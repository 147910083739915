.reference-slider{
    padding-bottom: calc(65rem/16);
    @media screen and (min-width: 768px){
        padding-bottom: calc(106rem/16);
    }
}
.reference-slider:before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(214rem/16);
    width: 85vw;
    background: var(--color-dark);
    border-bottom-right-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    @media screen and (min-width: 768px){
        height: calc(256rem/16);
        width: 63vw;
    }
    @media screen and (min-width: 1200px){
        height: calc(363rem/16);
    }
}

.reference-slider--bg-right.reference-slider--bg-wide:before {
    @media screen and (min-width: 768px) {
        width: 85vw;
    }
}
.reference-slider--bg-right:before {
    left: auto;
    right: 0;
    border-radius: 0;
    border-bottom-left-radius: calc(6rem/16);
    border-top-left-radius: calc(6rem/16);
}
.reference-slider__intro{
    font-size: calc(15rem/16);
    line-height: calc(28/18);

    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
    }
}
.reference-slider__title{
    color: var(--color-grey-50);
    font-family: var(--font-default-bold);
    font-size: calc(30rem/16);
    line-height: calc(66/60);
    letter-spacing: calc(2rem/16);
    opacity: 0.5;

    @media screen and (min-width: 1200px){
        font-size: calc(60rem/16);
        letter-spacing: calc(4rem/16);
    }
}

.base-color .reference-slider__title {
    color: var(--color-text-default);
    opacity: 1;
}

.reference-slider__button{
    position: absolute;
    bottom: calc(24rem/16);
    font-size: calc(15rem/16);
    line-height: calc(18rem/16);
    letter-spacing: calc(0.5rem/16);
    @media screen and (min-width: 768px){
        bottom: calc(40rem/16);
    }
}
