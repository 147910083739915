.image-gallery{
    @media screen and (min-width: 768px){
        max-width: calc(1708rem/16);
        width: 100%;
    }
}
.image-gallery__description{
    color: var(--color-dark-grey);
    @media screen and (max-width: 767px){
        margin-left: calc(22rem/16);
    }
}
.image-gallery__copyright{
    position: absolute;
    right: 0;
    top: 0;
    height: fit-content;
    width: fit-content;
    color: #fff;
    z-index: 1;
}
.image-gallery__background:after{
    content: '';
    opacity: 0.3;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: calc(6rem/16);
    background: linear-gradient(270deg, #000000 0%, rgba(0,0,0,0) 16.63%, rgba(0,0,0,0) 83.37%, #000000 100%);
    @media screen and (min-width: 768px){
        background: linear-gradient(270deg, #000000 0%, rgba(0,0,0,0) 6.63%, rgba(0,0,0,0) 93.65%, #000000 100%);
    }
}