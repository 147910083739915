.planer {
    padding: calc(22rem / 16);
    background: #fff;
    position: relative;
    min-height: calc(200rem / 16);
    font-size: calc(13rem / 16);

    @media screen and (min-width: 1200px){
        padding: calc(44rem / 16);
        font-size: calc(15rem / 16);
    }
}
.planer_sidebar {
    width: 100%;
    max-width: 100%;

    @media screen and (min-width: 768px) {
        width: calc(306rem / 16);
    }

    @media screen and (min-width: 1200px) {
        width: calc(284rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(410rem / 16);
    }
}
.planer__nav {
    padding: calc(10rem / 16) 0;
    z-index: 11;
    transition: background 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) 0;
    }
}
.planer__nav.is-affix {
    background: var(--color-light);
    box-shadow: 0 calc(10rem / 16) calc(10rem / 16) 0 rgba(62,61,64,0.04);
}

.planer__group + .planer__group {
    margin-top: calc(38rem / 16);
}
.planer__group--headline + .planer__group {
    margin-top: calc(22rem / 16);
}
.planer__group-title {
    letter-spacing: calc(1rem / 16);
    margin-bottom: calc(5rem / 16);
}
.planer__group-intro {
    margin-bottom: calc(20rem / 16);
}
.planer__group-info {
    font-size: calc(13rem / 16);
}


.planer rect.plankPart {
    fill: #CDA678;
    stroke: var(--color-dark);
}
.planer rect.plankPart:hover {
    fill: #b29067;
}
.planer .svg-surfacePlan {
    stroke: var(--color-danger);
}
.planer text.dimension__value {
    font-family: var(--font-default-bold);
    fill: var(--color-dark-grey);
}

.planer__fieldset {
    border-radius: calc(4rem / 16);
    padding: calc(12rem / 16);
    font-size: calc(12rem / 16);
    display: flex;
    flex-direction: column;
    height: 100%;

    @media screen and (min-width: 1400px) {
        padding: calc(22rem / 16);
        font-size: calc(15rem / 16);
    }
}
.planer__fieldset-icon {
    font-size: calc(33rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(44rem / 16);
    }
}
.planer__fieldset--disabled {
    opacity: .3;
    pointer-events: none;
}
.planer__fieldset--disabled .form-check-input {
    opacity: 1;
}
.planer__fieldset-icon.icon-no {
    font-size: calc(22rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(33rem / 16);
    }
}
.planer__fieldset-content {
    border-top: calc(1rem / 16) solid var(--color-grey);
    border-bottom: calc(1rem / 16) solid var(--color-grey);
    font-size: calc(13rem / 16);
    line-height: calc(22/13);
    padding: calc(12rem / 16) 0;
    margin: calc(10rem / 16) 0 calc(20rem / 16);
}

.planer__fieldset--active-state,
.planer__fieldset--img {
    opacity: .5;
    transition: opacity 200ms ease-in-out;
}
.is-not-selected.planer__fieldset,
.is-not-selected .planer__fieldset,
.is-selected .planer__fieldset,
.is-selected.planer__fieldset,
.is-active.planer__fieldset--img {
    opacity: 1;
}

.planer__fieldset--active-state:hover,
.planer__fieldset--img:hover {
    opacity: .7;
}

.planer__fieldset--active-border {
    opacity: .6;
    transition: opacity 200ms ease-in-out;
}
.planer__fieldset--active-border:hover {
    opacity: .9;
}
.planer__fieldset--active-border {
    border: calc(1rem / 16) solid var(--color-light);
}
.is-selected.planer__fieldset--active-border {
    border: calc(1rem / 16) solid var(--color-default);
}
.planer__fieldset--img .form-check-input {
    position: absolute;
    left: calc(11rem / 16);
    top: calc(11rem / 16);
    margin: 0;
}
.planer__fieldset--hide-input .form-check-input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.planer__fieldset.planer__fieldset--sm {
    font-size: calc(11rem / 16);
    line-height: calc(18/13);
    padding: calc(10rem / 16) calc(8rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(13rem / 16);
        padding: calc(14rem / 16) calc(12rem / 16);
    }
}
.planer__fieldset[data-count] {
    padding-right: calc(15rem / 16);

    @media screen and (min-width: 1200px) {
        padding-right: calc(30rem / 16);
    }
}
.planer__fieldset[data-count]:after {
    content: attr(data-count);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    position: absolute;
    font-size: calc(16rem / 16);
    right: calc(7rem / 16);
    top: calc(4rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(20rem / 16);
        right: calc(14rem / 16);
        top: calc(8rem / 16);
    }
}
.planer__fieldset-price {
    font-size: calc(13rem / 16);
    margin-top: calc(18rem / 16);
    word-break: normal;

    @media screen and (min-width: 1200px) {
        margin-top: calc(25rem / 16);
        font-size: calc(18rem / 16);
    }
}
.planer__fieldset-popover {
    position: relative;
    z-index: 1;
}

//.planer__fieldset--img .form-check-label:before,
//.planer__fieldset--active-state .form-check-label:before {
//    content: '';
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    opacity: 0;
//    transition: opacity 200ms ease-in-out;
//    box-shadow: 0 0 calc(12rem / 16) 0 rgba(0, 0, 0, .2);
//    border-radius: calc(4rem / 16);
//}
//.planer__fieldset--img:hover .form-check-label:before,
//.planer__fieldset--active-state:hover .form-check-label:before {
//    opacity: .6;
//}
//.planer__fieldset--img .form-check-input:checked ~ .form-check-label:before,
//.planer__fieldset--active-state .form-check-input:checked ~ .form-check-label:before {
//    opacity: 1;
//}

/* layout svg boxes */
.planer__fieldset.planer__fieldset--svg {
    padding: calc(5rem / 16);
}
.planer__fieldset--svg svg {
    max-width: calc(90rem / 16);
    max-height: 100%;
    height: auto;
    width: 100%;
}
.planer__fieldset--svg svg path {
    color: var(--color-light);
}
.planer__fieldset--svg .svg-ebene:not(.svg-ebene--grey) {display: none;}

.planer__fieldset--svg .svg-ebene--grey {
    fill: currentColor;
    color: transparent;
}
.planer__fieldset--svg .form-check-label:hover .svg-ebene--grey {
    stroke: var(--color-primary);
}
.planer__fieldset--svg .form-check-input:checked + .form-check-label .svg-ebene--grey {
    color: var(--color-primary);
    stroke: var(--color-primary);
}
.planer__fieldset--svg .svg-ebene--grey {
    stroke: var(--color-default);
}

/* plank pattern svg */
.planer__zoom-buttons {
    position: relative;
    z-index: 1;
}
.planer__zoom-item-tooltip {
    position: fixed;
    display: inline-block;
    background: #efefef;
    z-index: 2;
    padding: 0 calc(5rem / 16);
}


.planer__preview {
    max-width: 350px;
    margin: 0 auto;
}

#outlineSvg, #selectedConstellation svg,
.svg-outline__direction,
.svg-outline__letter,
.planer__preview,
.tp-content__outline__constellation svg {
    transition: transform 1s ease-out;
    max-width: 100%;
}
.planer__preview svg, .layout-pattern>img {
    margin: 0 auto;
}
.planer__preview .svg-outline__rect {
    stroke: #222;
    stroke-width: 1;
    fill: none;
}
.planer__preview .svg-outline__direction {
    width: 30px;
    height: 30px;
}
.planer__preview .svg-outline__letter {
    text-anchor: middle;
    font-weight: 700;
    font-size: 23px;
    fill: #f88027;
    alignment-baseline: middle;
    dominant-baseline: middle;
}
.planer__preview .svg-fill__pattern--horizontal {
    fill: url(#stripedHorizontal);
}
.planer__preview .svg-fill__pattern--vertical {
    fill: url(#stripedVertical);
}

.planer__svg > svg,
.icon-btn__icon svg.planer__svg {
    width: 100%;
    height: auto;
}


.planer__slope {
    display: grid;
    grid-template-columns: minmax(300px, 410px) 14%;
    justify-content: center;
}
.planer__slope svg {
    width: 100%;
    height: auto;
}
.disabled.planer__slope-input{
    opacity: .45;
    pointer-events: none;
}
.planer__slope-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(20rem / 16);
    transition: opacity 200ms ease-in-out;
}
.planer__slope-edit--vertical {
    flex-direction: column;
    padding: calc(20rem / 16) 0;
}
.planer__slope-edit-divider {
    font-size: calc(9rem / 16);
}

.planer__slope-edit.is-disabled {
    opacity: 0;
}

/* planer teaser */
.planer-product-item {
    position: relative;
    padding: calc(22rem / 16);
}
.planer-product-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.planer-product-item.is-selected:before {
    opacity: 1;
}
.planer-product-item__img {
    border: calc(1rem / 16) solid var(--color-light-grey);
}
.planer-product-item__title {
    font-size: calc(15rem / 16);
    line-height: 1;
    letter-spacing: calc(1rem / 16);
}
.planer-product-item__info {
    font-size: calc(12rem / 16);
    grid-template-columns: 1fr 1fr;
    grid-gap: calc(18rem / 16);
}
.planer-product-item__info-item {
    position: relative;
    padding-left: 0.75rem;
}
.planer-product-item__info-item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-left: calc(1rem / 16) solid var(--color-light-grey);
}

.planer__fieldset .btn,
.planer-product-item .btn {
    word-break: normal;
}

/* subsidiary */
.subsidiary-list li + li {
    margin-top: calc(6rem / 16);
}
.subsidiary-list__item {
    column-gap: inherit;
    background: var(--color-light);
    border-radius: calc(6rem / 16);
    padding: calc(11rem / 16) calc(22rem / 16);
    border: calc(1rem / 16) solid var(--color-light);
}
.subsidiary-list__item:not(.is-active):hover {
    border-color: var(--color-default);
}
.subsidiary-list__item.is-active {
    border-color: var(--color-success);
}

/* summary */
.planer__pdf {
    box-shadow: 0 calc(2rem / 16) calc(9rem / 16) 0 rgba(0,0,0,.5);
    margin: 0 auto;
    color: #fff;
    width: calc(210rem / 16);
    position: relative;
    max-width: 100%;
    background-size: cover;
}
.planer__pdf-logo {
    max-height: calc(22rem / 16);
    width: auto;
    max-width: 100%;
}
.planer__pdf-content{
    position: absolute;
    padding: calc(18rem / 16) calc(12rem / 16);
    font-size: calc(12rem / 16);
    line-height: calc(16/12);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.planer__pdf-text-small {
    font-size: calc(9rem / 16);
}