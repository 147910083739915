.blaze-slider__navigation-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

/* arrows */
.blaze-slider__arrow {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right:0;

    padding: calc(10rem/16);
    background: none;
    border: 0;
    height: 90%;

    display: flex;
    align-items: flex-end;
}
.blaze-slider__arrow-style{
    background: #fff;
    color: var(--color-default);
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.2);
    font-size: calc(8rem / 16);
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    border-radius: 50%;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.blaze-slider__arrow.blaze-slider__arrow--prev {
    left: 0;
    right: auto;
}
.blaze-slider__arrow.blaze-slider__arrow--prev .icon {
    transform: rotate(-180deg);
}
.blaze-slider__arrow[disabled] {
    pointer-events: none;
}
.blaze-slider__arrow[disabled] .icon{
    opacity: .3;
}
.blaze-slider__arrow:hover .blaze-slider__arrow-style,
.blaze-slider__arrow:focus:not(:focus-visible) .blaze-slider__arrow-style {
    outline: none;
    color: var(--color-primary);
}

/* dots */
.blaze-pagination{
    position: absolute;
    bottom: .3125rem;
}
.blaze-pagination button {
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    border-radius: 50%;
    background: var(--color-light-grey);
    border: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    flex-shrink: 0;
    -webkit-appearance: none;
    text-indent: -199999px;
    display: inline-block;
    margin:0 calc(3rem/16);
}
.blaze-pagination button.active {
    background: var(--color-primary);
}

//pause
.blaze-slider__pause{
    border: none;
    background: none;

    position: absolute;
    top: calc(25rem / 16);
    right: calc(5rem / 16);
    z-index: 1;
}
.blaze-slider__pause .icon{
    color: var(--color-primary);
}