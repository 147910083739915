.btn {
    padding: calc(10rem / 16) calc(12rem / 16);
    font-size: calc(15rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(22rem / 16);
    }
}
.btn-group--hover:hover,
.btn:not(.btn-link):hover {
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
}
.btn-group--hover {
    border-radius: calc(6rem / 16);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}
.btn-group--hover .btn:not(.btn-link):hover {
    box-shadow: none;
}
.stretch-link .btn:hover {
    box-shadow: unset;
}

/* sizes */
.btn.btn-sm {
    padding: calc(6rem / 16) calc(12rem / 16);
    font-size: calc(13rem / 16);
}
.btn-sm:not(.btn-link):hover {
    box-shadow: 0 0 calc(10rem / 16) 0 rgba(62,61,64,0.2);
}
.btn.btn-lg {
    padding: calc(15rem / 16) calc(22rem / 16);
}
.btn.btn-md {
    padding: calc(10rem / 16) calc(44rem / 16);
}
.btn.btn-xl {
    padding: calc(22rem/16) calc(30rem/16);

    @media screen and (min-width: 1200px) {
        padding: calc(22rem/16) calc(44rem/16);
    }
}
.btn-md-lg {
    padding: calc(10rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) calc(22rem / 16);
        font-size: calc(15rem / 16);
    }
}
.btn--grow {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: calc(266rem / 16);
    }
}
.btn--grow-sm {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: calc(180rem / 16);
    }
}
.btn-link.text-color-default:hover {color: var(--color-primary);}
.btn-link--light {
    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
}
/* colors */
.btn-primary {color: var(--color-primary-contrast);}
.btn-primary.disabled,
.btn-primary[disabled],
.btn-primary:focus,
.btn-primary:hover {
    background-color: var(--color-primary-dark);
    color: var(--color-primary-contrast)
}

.btn-white {
    background: #fff;
    color: var(--color-default);
}
.btn-white.is-active,
.btn-white:hover {
    color: var(--color-primary);
}

.btn-success,
.btn-success[disabled],
.btn-success:hover,
.btn-success:focus {color: #fff;}

/* styles */
.btn-shadow {box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);}
.btn-border {border: calc(1rem / 16) solid var(--color-light-grey);}
.btn-light-grey-outline {border: calc(1rem / 16) solid var(--color-light-grey);}
.btn-light-grey-outline:hover {background: #fff;}

/* button icons */
.btn__icon {
    vertical-align: -.1em;
    margin-right: calc(4rem / 16);
    font-size: calc(20rem / 16);
}
.btn__icon:not(.btn__icon--sm).icon-check {font-size: calc(16rem / 16);}
.btn__icon:not(.btn__icon--sm).icon-chevron {font-size: calc(7rem / 16);}

/* button icons size */
.btn-sm .btn__icon {
    font-size: calc(18rem / 16);
    vertical-align: -.2em;
}
.btn-sm .btn__icon--sm {
    font-size: calc(12rem / 16);
    vertical-align: -.1em;
}
.btn__icon--sm {
    font-size: calc(10rem / 16);
}
.btn__icon--sm.icon-category {
    font-size: calc(16rem / 16);
}
.btn__icon--md {
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.btn__icon--lg {
    font-size: calc(18rem / 16);
    vertical-align: -0.15em;
    margin-right: calc(10rem / 16);
}
svg.btn__icon {
    vertical-align: -.2em;
    height: calc(20rem / 16);
}
.btn__icon--fixed {
    width: calc(28rem / 16);
    text-align: center;
}

.btn__icon--right {
    margin-right: 0;
    margin-left: calc(4rem / 16);
}

.btn-with-icon {
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: calc(10rem / 16);
    align-items: center;
    justify-content: center;
}
.btn-with-icon .btn__icon {margin: 0; margin-top: calc(-1rem / 16)}

/* button info */
.btn__info-text {
    font-size: calc(12rem / 16);
    padding-right: calc(10rem / 16);
    margin-right: calc(5rem / 16);
    color: var(--color-dark-grey);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
    }
}
.btn__info-text:after {
    content: '';
    position: absolute;
    right: 0;
    top: calc(-4rem/16);
    bottom: calc(-2rem/16);
    width: calc(1rem / 16);
    background: var(--color-grey-50);
    opacity: .3;
}

.btn.icon-btn {
    padding: calc(5rem / 16);
    width: calc(44rem / 16);
    height: calc(44rem / 16);
    position: relative;
    font-size: calc(18rem / 16);
}
.icon-btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.icon-btn__icon--fill {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-btn.icon-btn--sm {
    width: calc(34rem / 16);
    height: calc(34rem / 16);
    font-size: calc(14rem / 16);
}
.icon-btn__icon.icon-webcut-nut {
    font-size: calc(12rem / 16);
}

/* cart button */
.btn-cart {
    position: relative;
}
.btn-cart__default {
    transition: opacity 200ms ease-in-out;
}
.is-finished .btn-cart__default,
.is-loading .btn-cart__default {
    opacity: 0;
}
.btn-cart__finished {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 200ms ease-in-out;
    font-size: calc(16rem / 16);
}
.is-finished .btn-cart__finished {
    opacity: 1;
}

//collapse btn
.collapse-btn {
    letter-spacing: calc(1rem / 16);
    font-size: calc(12rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }
}
.btn.collapse-btn:focus,
.btn.collapse-btn:hover {
    box-shadow: none;
}
.collapse-btn__icon {
    margin-left: calc(2rem / 16);
    font-size: calc(8rem / 16);

    @media screen and (min-width: 1200px) {
        margin-left: calc(6rem / 16);
        font-size: calc(11rem / 16);
    }
}
.collapse-btn.collapsed .collapse-btn__icon:not(.collapse-btn__icon--arrow):before {
    content: var(--icon-plus);
}

.collapse-btn__icon--arrow {
    transition: trasnform 200ms ease-in-out;
    margin-left: calc(1rem / 16);
    font-size: calc(6rem / 16);
}
.collapse-btn.collapsed .collapse-btn__icon--arrow {
    transform: scaleY(1);
}
.collapse-btn .collapse-btn__icon--arrow {
    transform: scaleY(-1);
}

.is-active .btn__text--active,
.btn__text--in-active {
    display: none;
}

.is-active .btn__text--in-active,
.btn__text--active {
    display: inline-block;
}

.tooltip-btn {
    background: none;
    border: 0;
    box-shadow: none;
    color: currentColor;
    padding: 0;
}

.btn.is-disabled {
    opacity: .65;
    cursor: pointer;

    &:hover {
        box-shadow: none;
    }
}

// button badge
.btn__badge {
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    border-radius: 50%;
    background: var(--color-primary);
    color: #fff;
    text-transform: uppercase;
    font-size: calc(9rem / 16);
    line-height: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-20deg);
}