.scroll-top {
    position: fixed;
    bottom: calc(10rem / 16);
    right: 0;
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    padding: calc(5rem / 16);
    box-shadow: 0 0 calc(10rem / 16) 0 rgba(62,61,64,0.2);
    border-top-left-radius: calc(6rem / 16);
    border-bottom-left-radius: calc(6rem / 16);
    background: #fff;
    border: 0;
    z-index: 11;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        bottom: calc(20rem / 16);
        width: calc(38rem / 16);
        height: calc(38rem / 16);
    }
}
.is-affix.scroll-top {
    opacity: .8;
}
.is-affix.scroll-top:hover {
    opacity: 1;
}
.scroll-top__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    line-height: 1;
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}

.scroll-top + .callback .callback__button {
    bottom: calc(60rem / 16);

    @media screen and (min-width: 768px) {
        bottom: calc(100rem / 16);
    }
}
