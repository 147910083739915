:root {
    --nav-right-list-item-spacing: calc(6rem / 16);
    --nav-dropdown-padding: calc(20rem / 16) 0 calc(20rem / 16) clamp(8px, 100vw, calc(50vw - calc(870rem / 16)));
    --nav-dropdown-width: calc(clamp(8px, 100vw, calc(50vw - 54.375rem)) + calc(294rem/16));
}
.navbar-container {
    position: sticky;
    top: 0;
    z-index: 12;
}
@media screen and (min-width: 768px) {
    .navbar-container:before {
        content: '';
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 140px;
        left: 0;
        background: var(--color-dark);
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms ease-in-out;
    }
    .navbar-container--mode:before{
        top: 200px;
    }
    .navbar-container--mode-big:before{
        top: 260px;
    }
}
.navbar-container.is-open:before {
    opacity: .3;
}
.navbar {
    padding: calc(10rem / 16) calc(22rem / 16);
    background: var(--color-light);
    font-size: calc(15rem / 16);
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(22rem / 16) 0;
        font-size: calc(13rem / 16);
        height: calc(140rem / 16);
    }

    @media screen and (min-width: 1600px) {
        font-size: calc(15rem / 16);
    }
}
.navbar--mode{
    @media screen and (min-width: 768px) {
        height: calc(200rem / 16);
    }
}
.navbar--mode-big{
    @media screen and (min-width: 768px) {
        height: calc(260rem / 16);
    }
}
.navbar:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    pointer-events: none;
    z-index: 10;
}
.navbar__container {
    width: 100%;
    max-width: calc(1706rem / 16);
    margin: 0 auto;
    display: grid;
    grid-gap: calc(5rem / 16);
    row-gap: calc(10rem / 16);
    grid-template-rows: auto auto;
    grid-template-columns: minmax(calc(64rem / 16), 25%) 1fr;
    grid-template-areas:
        "logo main"
        "search search";

    @media screen and (min-width: 768px) {
        grid-gap: 0;
        grid-template-columns: minmax(calc(100rem / 16), 9%) 47% 1fr;
        grid-template-areas:
            "logo search login"
            "main main main";
    }
}
.navbar__brand-img {
    max-height: calc(30rem / 16);
    max-width: 100%;
    height: auto;

    @media screen and (min-width: 768px) {
        max-height: calc(41rem / 16);
    }
}
.main-nav__item-content {
    background: none;
    border: 0;
    position: relative;
    display: flex;
    align-items: center;
    color: inherit;
    text-align: left;
}
.main-nav__item-arrow {
    font-size: calc(8rem / 16);
    margin-left: calc(5rem / 16);
}

/* navbar top */
.navbar__brand {
    width: 100%;
    grid-area: logo;

    @media screen and (min-width: 768px) {
        margin-right: calc(66rem / 16);
    }
}
.navbar__search {
    grid-area: search;
    width: 100%;
    max-width: calc(700rem / 16);
}

.typeahead__placeholder {
    border-radius: calc(6rem / 16);
    padding: calc(6rem / 16) calc(12rem / 16);
    font-size: calc(12rem / 16);
    color: var(--color-dark-grey);
    background: #fff;
    height: calc(44rem / 16);
}
.typeahead__form + .typeahead__placeholder {
    display: none;
}

.navbar__login {
    grid-area: login;
    line-height: calc(20/15);
}
.navbar__container-main {
    grid-area: main;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.main-nav__item-content-title {
    margin-bottom: calc(5rem / 16);
    letter-spacing: calc(1rem / 16);
    word-break: normal;

    @media screen and (min-width: 768px) {
        margin-bottom: calc(20rem / 16);
    }
}

.main-nav__item-content--toggle {
    padding: 0;

    @media screen and (min-width: 768px) and (hover: hover) {
        display: none;
    }
}
.main-nav__item.has-children > .main-nav__item-content--link {
    @media (hover: none) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}
.main-nav__item.has-no-children > .main-nav__item-content--toggle {
    @media (hover: none) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.navbar__container-anchor-nav {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 11;
    background: var(--color-light);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    visibility: hidden;
    padding: 0 calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}
.navbar__container-anchor-nav.is-visible {
    opacity: 1;
    visibility: visible;
}

.main-nav__title {
    margin-bottom: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        line-height: calc(20/15);
        letter-spacing: calc(1rem / 16);
        margin-bottom: calc(20rem / 16);
    }

    @media screen and (min-width: 768px) and (min-height: 850px) {
        margin-bottom: calc(30rem / 16);
        font-size: calc(15rem / 16);
    }
}

.main-nav__title__icon {
    margin-right: calc(10rem / 16);
    width: calc(58rem / 16);

    @media screen and (min-width: 768px) {
        margin-right: calc(15rem / 16);
    }

    @media screen and (min-width: 768px) and (min-height: 850px) {
        margin-right: calc(20rem / 16);
    }
}

.main-nav__scrolling-area-container {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
}