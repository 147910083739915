.zuschnitt-kantengrafik-wrapper {
    position: relative;
    padding: 0 0 20px 15px;
}

.zuschnitt-kantengrafik-wrapper .legend-axis {
    position: absolute;
    -webkit-backface-visibility: hidden;
}

.zuschnitt-kantengrafik-wrapper .legend-axis:after {
    content: "\2192";
    position: absolute;
    top: -3px;
    font-size: 15px;
    right: -22px;
}

.zuschnitt-kantengrafik-wrapper .legend-length {
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.zuschnitt-kantengrafik-wrapper .legend-width {
    left: 0;
    top: 50%;
    -webkit-transform: rotate(-90deg)  translate(-50%, 50%);
    -ms-transform: rotate(-90deg)  translate(-50%, 50%);
    transform: rotate(-90deg)  translate(-50%, 50%);
    transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
}

.zuschnitt-kantengrafik {
    padding: 25px 20px;
    position: relative;
}
.zuschnitt-kantengrafik img {
    width: 100px;
}

.zuschnitt-kantengrafik .legend-v,
.zuschnitt-kantengrafik .legend-h,
.zuschnitt-kantengrafik .legend-l,
.zuschnitt-kantengrafik .legend-r {
    position: absolute;
}

.zuschnitt-kantengrafik .legend-v {
    bottom: 0;
    left: 49px;
}

.zuschnitt-kantengrafik .legend-h {
    top: 2px;
    left: 80px;
}

.zuschnitt-kantengrafik .legend-l {
    top: 22px;
    left: 15px;
}

.zuschnitt-kantengrafik .legend-r {
    bottom: 22px;
    right: 15px;
}