.popover {
    border: 0;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    min-width: calc(230rem / 16);
}
.popover-body {
    padding: calc(16rem / 16);
}
.popover-header {
    border-bottom: 0;
    text-transform: none;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(15rem / 16);
    letter-spacing: normal;
    padding: calc(16rem / 16) calc(16rem / 16) calc(11rem / 16);
}
.popover-header + .popover-body {
    padding-top: 0;
}

/* sizes */
.popover--lg {
    width: calc(340rem / 16);
    max-width: 100%;

    @media screen and (min-width: 768px) {
        width: calc(843rem / 16);
        max-width: calc(843rem / 16);
    }
}