.reference-teaser{
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.2);
    overflow: hidden;
    color: #fff;
}
.reference-teaser:before,
.reference-teaser:after{
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%);
    opacity: 0.8;
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
    transition: opacity 200ms ease-in-out;
    z-index: 1;
}
.reference-teaser:before {
    background: linear-gradient(206.57deg, rgba(233,91,16,0) 0%, var(--color-primary) 100%);
}
.reference-teaser:hover:after,
.reference-teaser:before {
    opacity: 0;
}
.reference-teaser:hover:before {
    opacity: .8;
}
.reference-teaser__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
        transform: translateY(100%);
        transition: transform 200ms ease-in-out;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 calc(33rem / 16) calc(33rem / 16);
    }
}
.reference-teaser:hover .reference-teaser__content {
    transform: translateY(0);
}
.reference-teaser__title{
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    padding: calc(22rem / 16);
    font-size: calc(18rem/16);
    line-height: calc(24rem/16);
    letter-spacing: calc(1rem/16);
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 1200px){
        font-size: calc(24rem/16);
        line-height: calc(29rem/16);
        letter-spacing: calc(1.5rem/16);
        padding: calc(33rem / 16);
    }
}
.reference-teaser:hover .reference-teaser__title {
    @media screen and (min-width: 768px) {
        transform: translateY(calc(calc(33rem / 16) - calc(8rem/16)));
    }
}
.reference-teaser__content-text {
    display: none;

    @media screen and (min-width: 768px) {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}