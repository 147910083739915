.product-teaser {
    background: #fff;
    padding: calc(10rem / 16);
    grid-gap: calc(12rem / 16);
    font-size: calc(13rem / 16);
    line-height: calc(16 / 13);
    position: relative;

    @media screen and (min-width: 768px) {
        grid-template-columns: 18% 1fr calc(205rem / 16);
        padding: calc(22rem / 16);
        grid-gap: calc(22rem / 16);
        row-gap: 0;
        grid-template-areas:
            "img detail price"
            "detailBottom detailBottom price";
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: 18% 1fr calc(245rem / 16);
        grid-template-areas:
            "img detail price"
            "img detailBottom price";
    }
}

/* teaser grid areas */
@media screen and (min-width: 768px) {
    .product-teaser__img {grid-area: img;}
    .product-teaser__detail  {grid-area: detail;}
    .product-teaser__detail--bottom  {grid-area: detailBottom; margin-top: calc(5rem / 16);}
    //.product-teaser__actions  {grid-area: actions;}
    .product-teaser__price-container  {grid-area: price;}
}
.product-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.product-teaser:hover:before {
    opacity: 1;
}
@media screen and (max-width: 767px) {
    .product-teaser__img,
    .product-teaser__main-head {
        padding-right: calc(44rem / 16);
    }
}
.product-teaser__title-container {
    display: flex;
    grid-gap: calc(10rem / 16);
}
.product-teaser__actions {
    @media screen and (max-width: 767px) {
        position: absolute;
        right: calc(10rem / 16);
        top: calc(10rem / 16);
        width: calc(44rem / 16);
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: min-content min-content;
        justify-content: flex-end;
    }
}

.product-teaser__detail--bottom,
.product-teaser__detail {
    @media screen and (min-width: 1400px) {
        padding-left: calc(22rem / 16);
    }
}
.product-teaser__title {
    font-size: calc(15rem / 16);
    line-height: calc(23/18);
    text-transform: none;

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        letter-spacing: calc(1rem / 16);
    }
}
.product-teaser__img {
    @media screen and (max-width: 767px) {
        grid-gap: calc(10rem / 16);
        grid-template-columns: 38% 1fr;
    }
}

.product-teaser__badge {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: calc(11rem / 16);
        top: calc(11rem / 16);
        z-index: 1;
    }
}

.product-teaser__meta {
    @media screen and (min-width: 768px) {
        row-gap: calc(12rem / 16);
        margin-top: calc(12rem / 16);
        grid-template-columns: repeat(4, min-content);
    }
}
.product-teaser__meta-item {
    @media screen and (min-width: 768px) {
        position: relative;
        word-break: normal;
        width: calc(100rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(110rem / 16);
    }
}
.product-teaser__meta-item.product-teaser__meta-item--lg {
    @media screen and (min-width: 768px) {
        width: calc(128rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(128rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .product-teaser__meta-item + .product-teaser__meta-item:not(:nth-child(5)) {
        padding-left: calc(12rem / 16);
    }
    .product-teaser__meta-item + .product-teaser__meta-item:not(:nth-child(5)):before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-left: calc(1rem / 16) solid var(--color-light-grey);
    }

    .product-teaser__price-container:before {
        content: '';
        position: absolute;
        left: calc(-11rem / 16);
        top: 0;
        bottom: 0;
        width: calc(1rem / 16);
        background: var(--color-light-grey);
    }
}

/* sizes */
.product-teaser--md {
    @media screen and (min-width: 1400px) {
        grid-template-columns: 14% 41% 1fr 1fr;
    }
}

/* product offer teaser */
.product-teaser__check {
    position: absolute;
    left: 0;
    top: 0;
    border-bottom-right-radius: calc(6rem / 16);
    background: #fff;
    padding: calc(11rem / 16);
    z-index: 2;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
}
/* logge out view */
.product-teaser.product-teaser--logged-out {
    @media screen and (min-width: 768px) {
        grid-template-columns: 18% 38% 1fr;
        grid-template-areas:
            "img detail actions"
            "img detailBottom detailBottom";
    }
}
.product-teaser--logged-out .product-teaser__detail--bottom {
    margin-top: calc(30rem / 16);
}