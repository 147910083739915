.table--open-items td{
    padding: calc(10rem/16);
    background: #FFF;
    border: none;
}
.table--open-items th{
    padding: calc(17rem/16) calc(10rem/16);
}
.table--open-items th:first-child{
    padding-left: calc(22rem/16);
}
.table--open-items th:last-child{
    padding-right: calc(22rem/16);
}
.table--open-items .tablesort__parent td:first-child{
    border-top-left-radius: calc(6rem/16);
    padding-left: calc(22rem/16);
}
.table--open-items .tablesort__parent td:last-child{
    border-top-right-radius: calc(6rem/16);
    padding-right: calc(22rem/16);
}
.table.table--open-items .tablesort__parent td{
    padding-top: calc(20rem/16);
    border-bottom: calc(1rem/16) solid var(--color-light-grey);
}
.table--open-items .tablesort__child td{
    padding-bottom: calc(20rem/16);
}
.table--open-items .tablesort__child td:first-child{
    border-bottom-left-radius: calc(6rem/16);
    padding-left: calc(22rem/16);
}
.table--open-items .tablesort__child td:last-child{
    border-bottom-right-radius: calc(6rem/16);
    padding-right: calc(22rem/16);
}
.table--open-items .tablesort__spacer td{
    padding: calc(5rem/16) 0;
    background-color: var(--color-light);
}