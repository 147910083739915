.horizontal-teaser {
    background: #fff;
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        line-height: calc(26/15);
        border-radius: calc(6rem / 16);
    }
}
.container .horizontal-teaser {
    @media screen and (max-width: 767px) {
        margin: 0 calc(-22rem/16);
        overflow: hidden;
    }
}
@media screen and (min-width: 768px) {
    .horizontal-teaser:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        border-radius: calc(6rem / 16);
    }
    .horizontal-teaser:hover:before {
        opacity: 1;
    }
}
.horizontal-teaser__content {
    padding: calc(24rem / 16) calc(22rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(45rem / 16);
    }
}
.horizontal-teaser__content-title {
    font-size: calc(15rem / 16);
    line-height: calc(29/24);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(1.5rem / 16);
        font-size: calc(24rem / 16);
    }
}
.horizontal-teaser__content-btn {
    margin-top: calc(24rem / 16);
    @media screen and (min-width: 768px) {
        margin-top: calc(48rem / 16);
    }
}

.horizontal-teaser__bg {
    min-height: calc(136rem / 16);

    @media screen and (min-width: 768px) {
        min-height: calc(360rem / 16);
    }
}
.horizontal-teaser__bg-img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
        border-top-right-radius: calc(6rem / 16);
        border-bottom-right-radius: calc(6rem / 16);
    }
}

.horizontal-teaser--media-left .horizontal-teaser__img,
.horizontal-teaser--media-left .horizontal-teaser__bg-img {
    border-radius: 0;
    @media screen and (min-width: 768px) {
        border-top-left-radius: calc(6rem / 16);
        border-bottom-left-radius: calc(6rem / 16);
    }
}

@media screen and (max-width: 767px) {
    .horizontal-teaser.horizontal-teaser--no-fill{
        margin: 0;
        border-radius: calc(6rem / 16);
    }
    .horizontal-teaser--no-fill .horizontal-teaser__bg-img,
    .horizontal-teaser--no-fill .horizontal-teaser__img {
        border-top-left-radius: calc(6rem / 16);
        border-top-right-radius: calc(6rem / 16);
    }
}

/*horizontal teaser sm*/
.horizontal-teaser--sm .horizontal-teaser__content{
    padding: calc(24rem / 16) calc(22rem / 16);
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) calc(12rem / 16);
        padding-left: 0;
    }

    @media screen and (min-width: 1400px) {
        padding: calc(44rem / 16);
        padding-left: calc(22rem/16);
    }
}
.horizontal-teaser--sm .horizontal-teaser__content-btn{
    padding-top: calc(8rem / 16);
    margin-top: auto;
}
.horizontal-teaser--sm .horizontal-teaser__content-title{
    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
        letter-spacing: calc(1rem/16);
    }
}

.horizontal-teaser--sm-wide .horizontal-teaser__content {
    font-size: calc(13rem / 16);
    line-height: calc(24/13);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16) calc(22rem / 16) calc(22rem / 16) 0;
    }
}
.horizontal-teaser--sm-wide .horizontal-teaser__bg {
    min-height: calc(230rem / 16);
    @media screen and (min-width: 768px) {
        min-height: calc(230rem / 16);
    }
}
.horizontal-teaser--sm-wide .horizontal-teaser__content-title{
    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
        letter-spacing: calc(1rem/16);
    }
}
.horizontal-teaser--sm-wide .horizontal-teaser__content-btn{
    margin-top: calc(24rem / 16);
}