.content-box {
    padding: calc(22rem / 16);
    @media screen and (min-width: 768px){
        padding: calc(44rem / 16);
    }
}
.content-box__content + .content-box__content{
    margin-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(44rem/16);
    }
}
.content-box__title {
    margin-bottom: calc(24rem / 16);
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
        line-height: calc(20/15);
    }
}
.content-box__title-icon{
    font-size: calc(15rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
    }
}
.content-box__icon {
    font-size: calc(44rem / 16);
    margin-bottom: calc(22rem / 16);
}
.content-box + .content-box {
    margin-top: calc(44rem / 16);
}
.content-box--sm + .content-box--sm {
    margin-top: calc(11rem / 16);
}
.content-box.content-box--sm-content + .content-box.content-box--sm-content {
    margin-top: calc(22rem/16);
}
.content-box__icon--color{
    color: var(--color-primary);
    @media screen and (min-width: 768px){
        color: var(--color-default);
    }
}
.content-box__label{
    font-size: calc(15rem/16);
    line-height: calc(20/15);
    @media screen and (min-width: 768px){
        line-height: calc(26/15);
    }
}
.content-box__item{
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }
    font-size: calc(15rem/16);
    line-height: calc(22/15);
}
.content-box__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
}
.content-box__text-area{
    @media screen and (max-width: 767px){
        font-size: calc(13rem/16);
        line-height: calc(24/13);
    }
}
address.content-box__address{
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
    margin-bottom: 0;
    line-height: calc(26/15);
}
/* sizes */
.content-box--sm{
    padding: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
    }
}
.content-box--sm .content-box__title {
    margin-bottom: calc(12rem / 16);
}
.content-box--sm .content-box__content + .content-box__content{
    margin-top: 0;

    @media screen and (min-width: 768px){
        margin-top: calc(22rem/16);
    }
}

.content-box--sm-content .content-box__content + .content-box__content{
    margin-top: calc(11rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(22rem/16);
    }
}
.content-box--sm-content {
    line-height: calc(22/15);
}
.content-box__logo{
    width: 100%;
    max-width: calc(66rem/16);
    border: calc(1rem/16) var(--color-light-grey) solid;
    border-radius: calc(4rem/16);
}

/* shadow */
.content-box--shadow {
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
}