.sidebar-nav{
    position: relative;
    font-size: calc(14rem/16);
    line-height: calc(19/14);
    letter-spacing: calc(0.5rem/16);

}
.sidebar-nav:before{
    content: '';
    position: absolute;
    background-color: var(--color-grey-50);
    opacity: 0.5;
    width: calc(1rem/16);
    height: 100%;
    top: 0;
    left: 0;
}
.sidebar-nav--submenu{
    margin-top: calc(11rem/16);
}
.sidebar-nav__item + .sidebar-nav__item{
    margin-top: calc(22rem/16);
}
.sidebar-nav--submenu .sidebar-nav__item + .sidebar-nav__item{
    margin-top: calc(6rem/16);
}
.sidebar-nav__item:last-child{
    margin-top: calc(33rem/16);
}
.sidebar-nav__link{
    padding-left: calc(22rem/16);
    color: var(--color-dark);
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}
.sidebar-nav--submenu .sidebar-nav__link{
    padding-left: calc(33rem/16);
    color: var(--color-dark-grey);
}
.sidebar-nav__link.active{
    color: var(--color-primary);
    position: relative;
}
.sidebar-nav__link.active:before{
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    width: calc(2rem/16);
    height: 100%;
    top: 0;
    left: 0;
}
.sidebar-nav__link-icon{
    font-size: calc(12rem/16);
}
.sidebar-nav__link-icon-sm{
    font-size: calc(9rem/16);
}

.sidebar-box {
    position: sticky;
    top: calc(150rem / 16);
    background: #fff;
    padding: calc(11rem / 16);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);

    @media screen and (min-width: 768px){
        padding: calc(22rem / 16);
    }
    @media screen and (min-width: 1400px){
        padding: calc(44rem / 16);
    }
}