.summary {
    border-top: calc(1rem / 16) solid var(--color-grey);
    margin-top: calc(11rem / 16);
    padding-top: calc(11rem / 16);
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(22rem / 16);
        padding-top: calc(22rem / 16);
    }
}
.summary--sm {
    @media screen and (min-width: 768px) {
        margin-top: calc(11rem / 16);
        padding-top: calc(11rem / 16);
    }
}
.summary__info {
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    color: var(--color-dark-grey);
    margin-right: auto;
}

.summary__item {
    width: calc(410rem / 16);
    margin-left: auto;
    max-width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 60% 40%;
}
.summary__item + .summary__item {
    margin-top: calc(8rem / 16);
}
.summary__price{
    letter-spacing: calc(-.8rem/16);
    text-align: right;
}
.summary__item--total .summary__price {
    font-size: calc(20rem / 16);
}
.summary__item + .summary__item--total {
    border-top: calc(1rem / 16) solid var(--color-grey);
    padding-top: calc(8rem / 16);
}
.summary__item--lg {
    font-size: calc(18rem / 16);
}
.summary__item--lg .summary__price {
    font-size: calc(24rem / 16);
}
.summary__item-with-vat{
    font-size: calc(15rem/16);
}
.summary__price-with-vat{
    font-size: calc(15rem/16);
    letter-spacing: calc(-.8rem/16);
    text-align: right;
}
.summary__item--group {
    display: block;
    line-height: 1.45;
}