.range-slider {
    padding: calc(10rem / 16) calc(10rem / 16) 0;
}
.range-slider__input[readonly],
.range-slider__input {
    background: var(--color-light-grey);
    height: calc(30rem / 16);
    padding: calc(2rem / 16) calc(10rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    width: calc(54rem / 16);
    font-size: calc(13rem / 16);
    border: 0;
    color: currentColor;
}
.range-slider__input::-webkit-outer-spin-button,
.range-slider__input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.range-slider__input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.range-slider__values {
    margin: calc(20rem / 16) calc(-10rem / 16) 0;
    grid-gap: calc(10rem / 16);
}
.range-slider__values-item {
    position: relative;
}
.range-slider__values-item + .range-slider__values-item:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(-8rem / 16);
    width: calc(8rem / 16);
    height: calc(2rem / 16);
    background: currentColor;
}
.range-slider__values-item + .range-slider__values-item {
    justify-content: flex-end;
}
.range-slider__slider {
    height: calc(4rem / 16);
    border-radius: calc(2rem / 16);
    border: 0;
    box-shadow: none;
    background: var(--color-light);
}
.range-slider__slider .noUi-handle {
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    background: #fff;
    border-radius: 50%;
    border: calc(2rem / 16) solid var(--color-dark-grey);
    cursor: pointer;
    top: calc(-8rem / 16);
    box-shadow: none;
    transition: background 200ms ease-in-out, border-color 200ms ease-in-out;
}

.is-selected .noUi-handle:focus,
.is-selected .noUi-handle {
    background: var(--color-primary);
    border-color: var(--color-primary);
}
.range-slider__slider .noUi-handle:focus,
.range-slider__slider .noUi-handle:hover {
    border-color: var(--color-primary);
}

.range-slider__slider .noUi-handle:before,
.range-slider__slider .noUi-handle:after {
    content: none;
}

.range-slider__slider .noUi-handle:focus {
    outline: none;
}
html:not([dir=rtl]) .range-slider__slider.noUi-horizontal .noUi-handle {
    right: calc(-10rem / 16);
}
.range-slider__slider .noUi-connect {
    background: var(--color-dark-grey);
    box-shadow: none;
    height: calc(4rem / 16);
}

.range-slider__submit {
    margin: calc(12rem / 16) calc(-10rem / 16) 0;
}