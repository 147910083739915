.hero-teaser{
    background-color: #FFF;
}
.hero-teaser__content{
    padding: calc(22rem / 16) calc(22rem / 16) calc(11rem / 16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1200px){
        padding: calc(66rem/16);
        height: 100%;
    }
}
.hero-teaser__text{
    line-height: calc(24/15);
    margin-bottom: calc(10rem/16);
    @media screen and (min-width: 768px){
        line-height: calc(26/15);
        margin-bottom: 0;
    }
}
.hero-teaser__bg{
    min-height: calc(330rem/16);
    @media screen and (min-width: 768px){
        min-height: calc(480rem/16);
    }
}
.hero-teaser__bg .caption {
    border-top-left-radius: 0;
}
.hero-teaser__img{
    object-fit: cover;
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
    @media screen and (min-width: 768px){
        border-bottom-left-radius: 0;
        border-top-right-radius: calc(6rem/16);
    }
}
.hero-teaser__btn-icon{
    font-size: calc(7rem/16);
    vertical-align: middle;
}

/* sizes */
.hero-teaser--sm {
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    position: relative;
}
.hero-teaser--sm:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.hero-teaser--sm:hover:before {
    opacity: 1;
}
.hero-teaser--sm .hero-teaser__content {
    padding: calc(24rem / 16) calc(22rem / 16);
    display: block;

    @media screen and (min-width: 1200px) {
        padding: calc(45rem / 16);
    }
}
.hero-teaser--sm .hero-teaser__content-text {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        margin-top: calc(24rem / 16);
    }
}
.hero-teaser--sm .hero-teaser__content-title {
    font-size: calc(15rem / 16);
    line-height: calc(29/24);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(1.5rem / 16);
        font-size: calc(24rem / 16);
    }
}
.hero-teaser--sm .hero-teaser__img{
    border-radius: 0;
    border-top-left-radius: calc(6rem / 16);
    border-top-right-radius: calc(6rem / 16);
}