:root {
    --cart-teaser-gap: calc(12rem / 16)
}
.cart-teaser {
    background: #fff;
    padding: calc(10rem / 16);
    grid-gap: calc(12rem / 16);
    font-size: calc(13rem / 16);
    line-height: calc(16 / 13);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
    }
}
.cart-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: .1;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}

.cart-teaser__grid {
    grid-template-areas:
        "title title actions"
        "meta price price"
        "amount total total";
    grid-template-columns: 45% auto min-content;
    grid-gap: var(--cart-teaser-gap);

    @media screen and (min-width: 768px) {
        grid-template-areas:
        "title meta price amount total actions";
        grid-template-columns: 25% 1fr calc(100rem/16) minmax(calc(117rem / 16), 12%) calc(116rem / 16) auto;
        --cart-teaser-gap: calc(22rem / 16)
    }

    @media screen and (min-width: 1680px) {
        grid-template-columns: 29% 1fr calc(100rem / 16) minmax(calc(117rem / 16), 13%) calc(116rem / 16) auto;
        --cart-teaser-gap: calc(32rem / 16)
    }
}
.cart-teaser__col {
    display: flex;
    flex-direction: column;
    position: relative;
}
.cart-teaser__col--details {grid-area: details;}
.cart-teaser__col--availability {grid-area: availability;}
.cart-teaser__col--title {grid-area: title;}
.cart-teaser__col--total {grid-area: total;}
.cart-teaser__actions {grid-area: actions;}
.cart-teaser__col--meta {
    grid-area: meta;
    @media screen and (max-width: 767px) {
        margin-top: calc(-22rem / 16);
    }
}
.cart-teaser__col--price {grid-area: price;}
.cart-teaser__col--amount {
    grid-area: amount;

    @media screen and (max-width: 767px) {
        justify-content: flex-end;
    }
}
@media screen and (min-width: 768px) {
    .cart-teaser:not(.cart-teaser--group) .cart-teaser__col + .cart-teaser__col {
        padding-left: calc(7rem / 16);
        padding-top: calc(4rem / 16);
    }
    .cart-teaser:not(.cart-teaser--group) .cart-teaser__col + .cart-teaser__col:before {
        content: '';
        position: absolute;
        left: calc(-0px - var(--cart-teaser-gap) / 2);
        top: 0;
        bottom: 0;
        width: calc(1rem / 16);
        background: var(--color-light-grey);
    }
}
.cart-teaser__col-title {
    margin-bottom: calc(2rem / 16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(14rem / 16);
    }
}

.cart-teaser__title {
    font-size: calc(15rem / 16);
    line-height: calc(18/15);
    letter-spacing: normal;
}

/* group teaser */
.cart-teaser--group {
    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(22rem / 16);
    }
}
.cart-teaser--group .cart-teaser__grid {
    grid-template-areas:
        "title title actions"
        "availability total total";
    grid-template-columns: 60% 1fr auto;

    @media screen and (min-width: 768px) {
        row-gap: calc(24rem / 16);
        grid-template-areas:
        "title availability total actions"
        "details details details actions";
        grid-template-columns: 1fr calc(120rem / 16) calc(100rem / 16) auto;
    }
}

/* sizes */
.cart-teaser__col--check {grid-area: check;}
.cart-teaser__col--main {grid-area: main;}

.cart-teaser__value {
    font-size: calc(15rem / 16);
}

.cart-teaser__meta {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: calc(4rem / 16);
}
.cart-teaser__meta-item {
    position: relative;
}
.cart-teaser__meta-item + .cart-teaser__meta-item {
    padding-left: calc(12rem / 16);
}
.cart-teaser__meta-item + .cart-teaser__meta-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-left: calc(1rem / 16) solid var(--color-light-grey);
}

.cart-teaser__price{
    font-size: calc(18rem / 16);
    word-break: normal;
}

/* variants */
.cart-teaser--shadow:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.cart-teaser--shadow:hover:before {
    opacity: 1;
}


.cart-teaser--readonly .cart-teaser__grid {
    grid-template-areas: none;
    grid-template-columns: 100%;

    @media screen and (min-width: 768px) {
        grid-template-columns: 26% 1fr 1fr;
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: 28% 1fr 1fr 25%;
    }
}
.cart-teaser--readonly .cart-teaser__col {
    grid-area: auto;
}
.cart-teaser--readonly .cart-teaser__col--title {
    @media screen and (min-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 4;
    }

    @media screen and (min-width: 1400px) {
        grid-column: auto;
    }
}
.cart-teaser--readonly .cart-teaser__col--meta {
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}
.cart-teaser--readonly .cart-teaser__col--meta:before {
    display: none;

    @media screen and (min-width: 1400px) {
        display: block;
    }
}
.cart-teaser.cart-teaser--readonly .cart-teaser__col + .cart-teaser__col {
    @media screen and (min-width: 768px) {
        padding-left: 0;
    }
}

.cart-teaser--offer  {
    font-size: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(22rem / 16);
        font-size: calc(13rem / 16);
    }
}
.cart-teaser--offer.cart-teaser--offer .cart-teaser__grid {
    grid-template-areas:
        "check main";
    grid-template-columns: auto 1fr;
    --cart-teaser-gap: calc(8rem / 16);

    @media screen and (min-width: 1400px) {
        --cart-teaser-gap: calc(32rem / 16)
    }
}

@media screen and (min-width: 768px) {
    .cart-teaser--offer .cart-teaser__col--main {
        padding-left: calc(7rem / 16);
        padding-top: calc(4rem / 16);
    }
    .cart-teaser--offer .cart-teaser__col--main:before {
        content: '';
        position: absolute;
        left: calc(-0px - var(--cart-teaser-gap) / 2);
        top: 0;
        bottom: 0;
        width: calc(1rem / 16);
        background: var(--color-light-grey);
    }
}