th[role=columnheader]:not(.no-sort) {
    cursor: pointer;
    position: relative;
}

th[role=columnheader]:not(.no-sort):after {
    content: '';
    float: right;
    margin-top: calc(7rem/16);
    border-width: 0 calc(4rem/16) calc(4rem/16);
    border-style: solid;
    border-color: #404040 transparent;
    visibility: hidden;
    position: absolute;
    margin-left: calc(5rem/16);
    opacity: 0;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

th[aria-sort=ascending]:not(.no-sort):after {
    border-bottom: none;
    border-width: calc(4rem/16) calc(4rem/16) 0;
}

th[role=columnheader][aria-sort]:not(.no-sort):after {
    visibility: visible;
    opacity: 1;
}

th[role=columnheader]:not(.no-sort):after {
    visibility: visible;
    opacity: 0.4;
}

tr.tablesort__child{
    border-top-color: transparent;
}
tr.tablesort__child .table:last-child{
    border-color: transparent;
}
.table tbody tr.tablesort__parent td{
    border: none;
}
.tablesort__child .table-responsive{
    border: calc(1rem/16) var(--color-grey-50) solid;
    border-radius: calc(4rem/16);
}