.title-block {
    margin-bottom: calc(22rem / 16);
}
.title-block__intro {
    font-size: calc(18rem / 16);
    line-height: calc(28/18);
    margin-top: calc(24rem / 16);
}
.title-block--main-sm .title-block__intro {
    margin-top: calc(8rem / 16);
    font-size: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}