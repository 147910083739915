/*overlay*/
.checkout-overlay{
    width: 100%;
    z-index: 10;
    max-height: 70%;
    background-color: var(--color-light);
}
.checkout-overlay__top{
    height: calc(150rem/16);
    padding: calc(22rem/16) 0;
}
.checkout-overlay__bottom{
    padding: calc(22rem/16) 0;
}
.checkout-overlay__bottom-list{
    max-height: calc(100vh * 0.7 - calc(200rem/16));
    overscroll-behavior: contain;
}
.checkout-overlay.is-affix{
    box-shadow: 0 calc(-10rem/16) calc(20rem/16) 0 rgba(62,61,64,0.1);
}
.checkout-overlay__title{
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
        line-height: calc(22/15);
    }
}
.checkout-overlay__subtitle{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
}
.checkout-overlay__price{
    font-size: calc(20rem/16);
    line-height: calc(25/20);
    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        line-height: calc(29/24);
    }
}
.checkout-overlay__price-with-vat{
    font-size: calc(15rem/16);
}
.checkout-overlay__collapse-btn {
    font-size: calc(14rem / 16);
    letter-spacing: calc(.8rem / 16);
    display: flex;
    align-items: center;
}
.collapsed .checkout-overlay__collapse-btn-icon {
    transform: rotate(180deg);
    position: relative;
}
.checkout-overlay__collapse-btn-icon {
    font-size: calc(7rem / 16);
    transition: transform 200ms ease-in-out;
    margin-left: calc(5rem/16);
}

.checkout__success-icon{
    font-size: calc(25rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(38rem/16);
    }
}