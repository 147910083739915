.main-nav--level-1 {
    word-break: normal;
}
@media screen and (min-width: 768px) {
    .main-nav--level-1 {
        display: block;
        background-color: var(--color-light);
        color: var(--color-default);
        padding: calc(50rem / 16) 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
        pointer-events: none;
    }
    .main-nav__item.is-open > .main-nav--level-1 {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .main-nav__title--level-1 {
        width: 100%;
        display: flex;
        flex: 0 0 100%;
        font-family: var(--font-default-bold);
        font-weight: var(--font-default-bold-weight);
    }
    .main-nav__item--level-1 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
    }
    .main-nav__item-content--level-1 {
        width: 100%;
    }

    .main-nav__list--level-1 .main-nav__item {
        transition: opacity 200ms ease-in-out;
    }
    .main-nav__list--level-1 .main-nav__list:hover > .main-nav__item {
        opacity: .3;
    }
    .main-nav__list--level-1 .main-nav__item.main-nav__item:hover {
        opacity: 1;
    }
}

.main-nav--sm.main-nav--level-1 {
    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(140rem / 16));
        width: var(--nav-dropdown-width);
        min-width: calc(220rem / 16);
        padding: var(--nav-dropdown-padding);
    }

    @media screen and (min-width: 768px) and (min-height: 850px) {
        --nav-dropdown-padding: calc(90rem / 16) 0 calc(20rem / 16) clamp(8px, 100vw, calc(50vw - calc(870rem / 16)))
    }
}
.navbar__container:has(~ .navbar__container-anchor-nav.is-visible) .main-nav--sm.main-nav--level-1 {
    @media screen and (min-width: 768px) {
        padding-top: calc(50rem / 16);
    }

    @media screen and (min-width: 768px) and (min-height: 850px) {
        padding-top: calc(90rem / 16);
    }
}

.navbar--mode .main-nav--sm.main-nav--level-1 {
    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(200rem / 16));
    }
}
.navbar--mode-big .main-nav--sm.main-nav--level-1 {
    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(260rem / 16));
    }
}

.main-nav--sm .main-nav__item-content--level-1 {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-primary);
    letter-spacing: calc(.9rem / 16);
    padding: calc(8rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16) 0;
    }
}

.main-nav__item-content-title .main-nav__item-arrow,
.main-nav__item-content--level-1 .main-nav__item-arrow {
    flex-shrink: 0;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    background: var(--color-dark);
    color: var(--color-light);
    border-radius: 50%;
    font-size: calc(8rem / 16);
    margin-left: calc(10rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;


    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(18rem / 16);
        height: calc(18rem / 16);
        font-size: calc(6rem / 16);
        margin-left: calc(3rem / 16);
        margin-top: calc(1rem / 16);
    }
}