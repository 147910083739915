@font-face {
    font-family: "Inter-Regular";
    src: url('fonts/Inter-Regular.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter-Bold";
    src: url('fonts/Inter-Bold.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "Inter-Light";
    src: url('fonts/Inter-Light.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
}

/* Important:
*  Font urls have to be relative to the current file or start with the "fonts" alias e.g. "fonts/font-name.woff"
*  The "fonts" alias resolves to /web/static/fonts/
*/