.data-row {
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
    }
}
.data-row__head {
    background: var(--color-light);
    color: var(--color-dark-grey);
    padding: calc(6rem / 16) calc(10rem / 16);
    border-radius: calc(6rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16);
    }
}
.data-row__item {
    border-bottom: calc(1rem / 16) solid rgba(158, 158, 159, 0.3);
    padding: calc(6rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16);
    }
}