.data-table {
    line-height: calc(20/15);
}
.data-table__head {
    grid-gap: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16) calc(11rem / 16) calc(12rem / 16);
        border-radius: calc(6rem / 16);
        grid-template-columns: 1fr 14% 14% 18% 14% calc(120rem / 16);
    }

    @media screen and (min-width: 1400px) {
        grid-gap: calc(22rem / 16);
        grid-template-columns: 19.7% 11.5% 14.5% 11.5% 11.5% auto;
    }
}
.data-table__row {
    grid-gap: calc(11rem / 16);
    padding: calc(16rem / 16) calc(22rem / 16);
    grid-template-columns: 1fr calc(44rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-grey);
    align-items: start;

    @media screen and (min-width: 768px) {
        align-items: center;
        padding: calc(11rem / 16);
        grid-template-columns: 100%;
        font-size: calc(13rem / 16);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }

    @media screen and (min-width: 1400px) {
        font-size: calc(15rem / 16);
        grid-gap: calc(22rem / 16);
        padding: calc(22rem / 16);
        grid-template-columns: 80% auto;
    }
}
.data-table__row-detail {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: calc(11rem / 16);
    row-gap: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 14% 14% 18% 14% calc(120rem / 16);
    }

    @media screen and (min-width: 1400px) {
        grid-gap: calc(22rem / 16);
        grid-template-columns: 24% 15% 18% 14% 14% calc(120rem / 16);
    }
}
.data-table__row-detail-fill {
    @media screen and (max-width: 767px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}
.data-table__row.is-active {
    background: #fff;
}
.data-table__bottom  {
    padding: calc(22rem / 16) calc(11rem / 16);
    border-bottom-right-radius: calc(6rem / 16);
    border-bottom-left-radius: calc(6rem / 16);
}

.data-table__row-actions {
    grid-gap: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr auto;
    }
}

.data-table__row--disabled {
    opacity: .5;
}