:root {
    --main-nav-level-0-item-spacing: calc(8rem / 16);
}


.main-nav__list--level-0 {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        margin-left: calc(var(--main-nav-level-0-item-spacing) * -1);
        margin-right: calc(var(--main-nav-level-0-item-spacing) * -1);
    }

    @media screen and (min-width: 1200px) {
        --main-nav-level-0-item-spacing: calc(16rem / 16);
    }

    @media screen and (min-width: 1600px) {
        --main-nav-level-0-item-spacing: calc(28rem / 16);
    }
}

.main-nav__item--level-0 {
    @media screen and (min-width: 768px) {
        position: static;
        padding: 1rem var(--main-nav-level-0-item-spacing);
    }
}

.main-nav__item-arrow--level-0 {
    color: var(--color-grey-50);
    font-size: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-40%);
        transition: transform 200ms ease-in-out;
    }
}
.main-nav__item-content--level-0 {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: 768px) {
        padding: calc(10rem / 16) calc(15rem / 16) calc(10rem / 16) 0;
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(.5rem / 16);
        padding: calc(10rem / 16) calc(18rem / 16) calc(10rem / 16) 0;
    }
}
@media screen and (min-width: 768px) {
    .main-nav--level-0 {
        display: flex;
        flex: auto;
        align-items: center;
        height: 100%;
    }
    .main-nav__item-content--level-0:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: calc(44rem / 16);
        height: calc(2rem / 16);
        transform: scaleX(0) translateX(calc(-50% - calc(9rem / 16)));
        transform-origin: left;
        transition: transform 200ms ease-in-out;
        background: var(--color-primary);
    }
    .main-nav__item-content--level-0:hover:after,
    .main-nav__item.active > .main-nav__item-content--level-0:after,
    .main-nav__item.is-open > .main-nav__item-content--level-0:after {
        transform: scaleX(1) translateX(calc(-50% - calc(9rem / 16)));
    }

    .main-nav__item.is-open > .main-nav__item-content--level-0,
    .main-nav__item.is-active > .main-nav__item-content--level-0 {
        color: var(--color-primary);
    }
    .main-nav__item--level-0.is-open .main-nav__item-arrow--level-0 {
        transform: translateY(-60%) scaleY(-1);
    }
}

/* navbar right */
.nav-list {
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        margin-left: calc(var(--nav-right-list-item-spacing) * -1);
        margin-right: calc(var(--nav-right-list-item-spacing) * -1);
        --nav-right-list-item-spacing: calc(6rem / 16);
    }

    @media screen and (min-width: 1200px) {
        --nav-right-list-item-spacing: calc(8rem / 16);
    }

    @media screen and (min-width: 1400px) {
        --nav-right-list-item-spacing: calc(15rem / 16);
    }
}
.nav-list__item {
    line-height: 1;
}
.nav-list__item + .nav-list__item {
    margin-left: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(2rem / 16);
    }

    @media screen and (min-width: 1400px) {
        margin-left: calc(10rem / 16);
    }
}
.nav-list__item-link {
    display: block;
    background: none;
    border: 0;
    padding: 0 var(--nav-right-list-item-spacing);
    position: relative;
    letter-spacing: calc(.5rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-default);

    @media screen and (min-width: 768px) {
        padding: calc(16rem / 16) var(--nav-right-list-item-spacing);
    }
}
@media screen and (min-width: 768px) {
    .nav-list__item-link:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: calc(44rem / 16);
        max-width: 100%;
        height: calc(2rem / 16);
        transform: scaleX(0) translateX(-50%);
        transform-origin: left;
        transition: transform 200ms ease-in-out;
        background: var(--color-primary);
    }
    .nav-list__item-link:hover:after,
    .nav-list__item-link:focus:after,
    .active > .nav-list__item-link:after,
    .is-open > .nav-list__item-link:after {
        transform: scaleX(1) translateX(-50%);
    }
}

.nav-list__item-icon {
    height: calc(17rem / 16);
    width: calc(17rem / 16);

    @media screen and (min-width: 1400px) {
        height: calc(20rem / 16);
        width: calc(20rem / 16);
    }
}
.nav-list__item-icon-sm {
    height: calc(14rem / 16);
    width: calc(14rem / 16);
}
.nav-list .nav-list__dropdown {
    display: block;
    position: fixed;
    right: 0;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
    pointer-events: none;
    z-index: 10;
    top: 0;
    height: 100vh;
    width: 100%;
    padding: calc(40rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 100%;
        height: calc(100vh - calc(140rem / 16));
        width: calc(480rem / 16);
        padding: calc(66rem / 16) calc(107rem / 16);
    }
}
.navbar--mode .nav-list .nav-list__dropdown{
    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(200rem / 16));
    }
}
.navbar--mode-big .nav-list .nav-list__dropdown{
    @media screen and (min-width: 768px) {
        height: calc(100vh - calc(260rem / 16));
    }
}
.is-open > .nav-list__dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.nav-list__item-count {
    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        font-family: var(--font-default);
        vertical-align: -0.15em;
        margin-left: calc(3rem / 16);
    }

    @media screen and (max-width: 767px) {
        position: absolute;
        bottom: calc(-1rem / 16);
        right: calc(5rem / 16);
        width: calc(6rem / 16);
        height: calc(6rem / 16);
        border-radius: 50%;
        font-size: 0;
        background: var(--color-primary);
        transform: scale(0);
        transition: transform 200ms ease-in-out;
    }
}
.nav-list__item-count.is-active {
    @media screen and (max-width: 767px) {
        transform: scale(1);
    }
}