.footer{
    background-color: #FFF;
    padding-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(66rem/16);
    }
}
.footer__headline{
    letter-spacing: calc(1rem/16);
}
.footer__text{
    font-size: calc(13rem/16);
}
.footer__menu-grid{
    @media screen and (min-width: 768px){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: calc(22rem/16);
        padding-left: 7%;
    }
}
.footer__menu{
    margin: calc(16rem/16) 0;
    @media screen and (min-width: 768px){
        margin: 0;
        max-width: calc(200rem/16);
    }
}
.footer__menu-headline{
    font-size: calc(15rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: calc(1rem/16);
}
.footer__menu-list{
    font-size: calc(14rem/16);
    letter-spacing: calc(0.5rem/16);
}
.footer__menu-list > li{
    line-height: calc(16/14);
}
.footer__menu-list > li + li{
    margin-top: calc(13rem/16);
}
.footer__social{
    @media screen and (min-width: 768px){
        margin-top: calc(44rem/16);
    }
}

.footer__lang {
    background-color: var(--color-light);
    border: none;
    display: flex;
    align-items: center;
    height: calc(44rem / 16);
    padding-right: calc(12rem/16);
    width: 100%;
    font-size: calc(15rem/16);
    letter-spacing: calc(0.5rem/16);
    line-height: calc(15/15);
    margin-top: calc(33rem/16);
    @media screen and (min-width: 768px){
        width: fit-content;
        margin-top: 0;
        padding-right: calc(20rem/16);
    }
}
.footer__lang-flag{
    flex-shrink: 0;
}
.footer__lang-switch{
    font-size: calc(13rem/16);
    letter-spacing: 0;
    color: var(--color-grey-50);
    position: relative;
    padding-left: calc(6rem/16);
    margin-left: calc(6rem/16);
    flex-grow: 1;
    @media screen and (min-width: 768px){
        padding-left: calc(12rem/16);
        margin-left: calc(12rem/16);
    }
}
.footer__lang-switch:before{
    content: '';
    position: absolute;
    width: calc(1rem/16);
    height: calc(22rem/16);
    left: 0;
    background-color: var(--color-grey-50);
    opacity: 0.3;
}
.footer__arrow-icon{
    color: var(--color-dark-grey);
    font-size: calc(6rem/16);
    vertical-align: middle;
}
.footer__bottom{
    padding: calc(11rem/16) 0;
    position: relative;
    margin-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(22rem/16) 0;
        margin-top: calc(44rem/16);
    }
}
.footer__bottom:before{
    content: '';
    background-color: var(--color-light-grey);
    position: absolute;
    height: calc(1rem/16);
    width: 100%;
    top: 0;
    left: 0;
}
.footer__bottom-logo{
    height: calc(22rem/16);
}
.footer__bottom-copyright{
    color: var(--color-grey-50);
    font-size: calc(13rem/16);
    margin-left: calc(20rem/16);
}
.footer__bottom-link{
    font-size: calc(13rem/16);
    text-decoration: underline;
    color: var(--color-grey-50);
    display: inline-block;
}
.footer__bottom-link:not(:last-child){
    margin-right: calc(21rem/16);
    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }
}