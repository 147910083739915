.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    line-height: 1;
}
.loading-spinner__item {
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    background-color: currentColor;

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-pulse .4s infinite ease-in-out alternate;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
.loading-spinner__item + .loading-spinner__item {
    margin-left: calc(6rem / 16);
}
@keyframes loading-spinner-pulse {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: .35;
        transform: scale(.75);
    }
}

.icon-btn .loading-spinner__item {
    width: calc(8rem / 16);
    height: calc(8rem / 16);
}
.icon-btn .loading-spinner__item + .loading-spinner__item {
    margin-left: 0;
}