.infoBox >img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: calc(2rem / 16);
}
.google-map__infobox {
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
}

.google-map__infobox-content {
    padding: calc(16rem / 16) calc(20rem / 16);
}
.google-map__infobox-title {
    font-size: calc(15rem / 16);
    line-height: calc(29/24);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}