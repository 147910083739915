.material-group {
    background: #fff;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
    font-size: calc(12rem / 16);
    padding: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        padding: calc(22rem / 16) calc(22rem / 16) calc(22rem / 16) calc(66rem / 16);
    }
}
.material-group--error {
    border: calc(1rem / 16) solid var(--color-danger);
}
.material-group__title {
    font-size: calc(13rem / 16);
    letter-spacing: calc(.5rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        letter-spacing: calc(1rem / 16);
    }
}
.material-group__toggle {
    position: absolute;
    top: calc(78rem / 16);
    left: calc(22rem / 16);
}
.material + .material,
.material-group + .material-group {
    margin-top: calc(11rem / 16);
}

.material {
    position: relative;
    border: calc(1rem / 16) solid var(--color-light-grey);
    padding: calc(6rem / 16);
    grid-template-columns: 25% 1fr min-content;
    grid-gap: calc(11rem / 16);
    font-size: calc(12rem / 16);
    overflow: hidden;
    min-height: calc(110rem / 16);

    @media screen and (min-width: 1200px) {
        grid-template-columns: 15% 30% 1fr;
    }

    @media screen and (min-width: 1400px) {
        grid-gap: calc(22rem / 16);
        font-size: calc(13rem / 16);
        grid-template-columns: 15% 37% 1fr;
    }
}
.material__img {
    border-radius: calc(4rem / 16);
}
.material__title {
    font-size: calc(14rem / 16);
    line-height: calc(18/15);
    letter-spacing: normal;

    @media screen and (min-width: 1400px) {
        font-size: calc(15rem / 16);
    }
}

.material__detail {
    position: relative;
    padding-right: calc(54rem / 16);
    transition: transform 200ms ease-in-out;
}
.material__detail-actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.material__detail-meta {
    line-height: calc(24/13);
    margin-left: auto;
    padding: calc(7rem / 16) calc(22rem / 16) 0 0;

    @media screen and (min-width: 768px) {
        padding: calc(7rem / 16) calc(22rem / 16);
        max-width: calc(238rem / 16);
    }
}
.material__detail-meta:after,
.material__detail-meta:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(1rem / 16);
    background: var(--color-light-grey);
}
@media screen and (max-width: 767px) {
    .material__detail-meta:before {
        content: none;
    }
}
.material__detail-meta:after {
    left: auto;
    right: 0;
}
.material__link {
    position: absolute;
    right: calc(11rem / 16);
    top: 50%;
    transform: translateY(-50%);
}

.material__expired-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255, .6);
}

.material__badge {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: calc(11rem / 16);
        top: calc(11rem / 16);
        z-index: 1;
    }
}

.material--is-expired {
    border-color: var(--color-danger);
}

/* sizes */
.material.material--lg {
    padding-right: calc(40rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(11rem / 16);
        padding-right: calc(50rem / 16);
        grid-template-columns: 12% 1fr;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 12% 31% 1fr;
    }
}
.material--lg .material__detail {
    padding-right: 0;
    grid-column-end: 3;
    grid-column-start: 2;

    @media screen and (min-width: 1200px) {
        grid-column-end: auto;
        grid-column-start: auto;
    }
}
.material--lg .material__detail-meta {
    padding-left: 0;
    margin-left: 0;

    @media screen and (min-width: 1200px) {
        margin-left: auto;
        padding-left: calc(22rem / 16);
    }
}
.material--lg .material__detail-meta:before {
    content: none;

    @media screen and (min-width: 1200px) {
        content: '';
    }
}

.material--lg .material__detail-meta {
    @media screen and (max-width: 767px) {
        padding-right: 0;
    }
}
.material--lg .material__detail-meta:after {
    @media screen and (max-width: 767px) {
        content: none;
    }
}