.localized-video__select{
    height: calc(44rem/16);
    border: none;
    font-size: calc(13rem/16);
    letter-spacing: calc(0.5rem/16);
    color: var(--color-dark-grey);
    padding: calc(18rem/16) calc(12rem/16);
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        height: calc(55rem/16);
        font-size: calc(15rem/16);
        padding: calc(18rem/16) calc(22rem/16);
    }
}
.localized-video__head{
    padding: 0 calc(22rem / 16) calc(11rem / 16);
    @media screen and (min-width: 768px){
        padding: 0;
        margin-bottom: calc(30rem/16);
    }
}
.localized-video__head .title-block{
    @media screen and (max-width: 767px){
        margin-bottom: calc(10rem/16);
    }
}
.localized-video__select-change{
    color: var(--color-grey-50);
    font-size: calc(13rem/16);
    letter-spacing: 0;
    font-family: var(--font-default);
    padding-left: calc(6rem/16);
    margin-left: calc(6rem/16);
    position: relative;
    display: flex;
    align-items: center;
}
.localized-video__select-change:before{
    content: '';
    background-color: var(--color-grey-50);
    opacity: 0.3;
    left: 0;
    height: 100%;
    width: 1px;
    position: absolute;
}
.localized-video__select-icon{
    color: var(--color-dark);
    font-size: calc(5rem/16);
    margin-left: calc(59rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(28rem/16);
    }
}