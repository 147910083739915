.promotion-teaser {
    background: #fff;
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    position: relative;
}
.promotion-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.promotion-teaser:hover:before {
    opacity: 1;
}
.promotion-teaser__content {
    padding: calc(22rem / 16);
}
.promotion-teaser__text {
    margin-top: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(10rem / 16);
    }
}
.promotion-teaser__title {
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(23/18);
    }
}
.promotion-teaser__price {
    grid-gap: calc(5rem / 16);
    align-items: baseline;
    grid-template-columns: minmax(calc(80rem / 16), 20%) 1fr;
}
.promotion-teaser__price-value {
    font-size: calc(20rem / 16);
    line-height: calc(22/20);

    @media screen and (min-width: 768px){
        font-size: calc(22rem / 16);
    }
}
.promotion-teaser__price + .promotion-teaser__price {
    margin-top: calc(8rem / 16);
}

/* sizes */
.promotion-teaser--lg .promotion-teaser__price-container {
    @media screen and (min-width: 786px) {
        display: flex;
    }
}
.promotion-teaser--lg .promotion-teaser__price {
    grid-template-columns: 50% 1fr;
}
.promotion-teaser--lg .promotion-teaser__price + .promotion-teaser__price {
    margin-top: 0;
    @media screen and (min-width: 768px) {
        margin-left: calc(20rem / 16);
    }
}