.decor-teaser {
    background: #fff;
    padding: calc(11rem / 16);
    font-size: calc(13rem / 16);
}
@media screen and (min-width: 768px) {
    .decor-teaser:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
        opacity: 0.4;
        transition: opacity 200ms ease-in-out;
        border-radius: calc(6rem / 16);
    }
    .decor-teaser:hover:before {
        opacity: 1;
    }
}
.decor-teaser__head {
    letter-spacing: calc(1rem / 16);
    font-size: calc(15rem / 16);
}
.decor-teaser__title {
    font-size: calc(15rem / 16);
    line-height: calc(20/15);
    letter-spacing: calc(1rem / 16);
    margin-bottom: calc(12rem / 16);
}

.decor-teaser__meta-item {
    position: relative;
}
.decor-teaser__meta-item + .decor-teaser__meta-item {
    padding-left: calc(12rem / 16);
    margin-left: calc(11rem / 16);
}
.decor-teaser__meta-item + .decor-teaser__meta-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(1rem / 16);
    background: var(--color-light-grey);
}

/* styles */
.decor-teaser--color-only {
    padding: 0;
}