.alert-notification--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    max-height: 85vh;
    pointer-events: none;
    z-index: 1056;

    @media screen and (min-width: 768px){
        bottom: calc(26rem / 16);
        left: calc(26rem / 16);
    }
}
.alert-notification__container {
    pointer-events: auto;
    width: 100%;
    max-width: calc(686rem / 16);
    overflow: hidden;
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    padding: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16);
    }
}

/* item style in bootstrap/scss/_toasts.scss */