.number-spinner {
    border: calc(1rem / 16) solid var(--color-light-grey);
    background: #fff;
    border-radius: calc(6rem / 16);
    grid-template-columns: 1fr auto;
    font-size: calc(15rem / 16);
    padding: calc(10rem / 16);
    min-width: calc(110rem / 16);
}
.number-spinner .number-spinner__input {
    height: calc(24rem / 16);
    padding: calc(2rem / 16);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    max-width: calc(44rem / 16);
    background: var(--color-light);
    border-color: var(--color-light-grey);
}
.number-spinner__input::-webkit-outer-spin-button,
.number-spinner__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner__input[type=number] {
    -moz-appearance: textfield;
}
.btn.number-spinner__btn,
.number-spinner__btn {
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    border-radius: 50%;
    background: var(--color-dark);
    color: #fff;
    padding: calc(2rem / 16);
    font-size: calc(10rem / 16);
}

.btn.number-spinner__btn:disabled {
    pointer-events: auto;
    cursor: default;
    opacity: .35;
}

.number-spinner__label {
    word-break: normal;
    margin-right: calc(5rem / 16);
}