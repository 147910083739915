.dropdown-toggle--icon {
    font-size: calc(18rem / 16);
}
.dropdown-toggle--icon:after {
    content: none;
}
.dropdown-menu {
    border: 0;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.1);
    font-size: calc(14rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: calc(20rem / 16);
}
.dropdown-menu__title {
    font-size: calc(15rem / 16);
    letter-spacing: calc(1rem / 16);
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item {
    background: none;
}
.is-active.dropdown-item{
    color: var(--color-primary);
}
.dropdown-item {
    font-size: calc(14rem / 16);
    letter-spacing: calc(.5rem / 16);
    display: flex;
    align-items: center;
    line-height: calc(18/14);
    padding: 0;
    color: var(--color-dark);
}
.dropdown-menu__list li + li {
    margin-top: calc(11rem / 16);
}
.dropdown-item__arrow {
    font-size: calc(8rem / 16);
    margin-left: calc(6rem / 16);
    margin-top: calc(1rem / 16);
}

.dropdown__btn {
    color: inherit;
    border: none;
    background: none;
    padding: 0;
    font-size: calc(13rem/16);
    line-height: calc(16/13);
    text-align: start;

    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        line-height: calc(18/15);
    }
}
.dropdown__btn:not(.dropdown__btn--text){
    padding: calc(17rem/16) calc(22rem/16);
    background-color: #FFF;
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(62,61,64,0.1);
    border-radius: calc(4rem/16);

    font-family: var(--font-default-bold);
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px){
        max-width: calc(410rem/16);
        padding: calc(24rem/16) calc(33rem/16);
    }
}
.dropdown__btn-icon{
    font-size: calc(5rem/16);
    transition: transform 200ms ease-in-out;
}
.dropdown__btn.show .dropdown__btn-icon{
    color: var(--color-primary);
    transform: rotate(180deg);
}
.dropdown__btn-subtitle{
    font-family: var(--font-default-bold);
    font-size: calc(11rem/16);
    line-height: calc(13/11);
    color: var(--color-grey-50);
    text-align: start;
    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
        line-height: calc(16/13);
    }
}
/*submenu*/
.dropdown-menu__submenu{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFF;
    padding: calc(22rem/16) calc(22rem/16) calc(22rem/16) calc(22rem/16);
    width: 100%;
    border-radius: calc(4rem/16);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    z-index: 1;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.1);
    height: fit-content;
    min-height: 100%;
    @media screen and (min-width: 768px){
        padding: calc(44rem/16) calc(56rem/16) calc(44rem/16) 100%;
        z-index: -1;
        width: calc(640rem/16);
    }
}
.dropdown-menu__submenu:after{
    content: '';
    position: absolute;
    width: calc(1rem/16);
    height: calc(100% - calc(88rem/16));
    margin: calc(44rem/16) 0;
    background-color: var(--color-grey-50);
    opacity: 0.5;
    top: 0;
    left: calc(247rem/16);
    display: none;
    @media screen and (min-width: 768px){
        display: block;
    }
}
.is-open.dropdown-menu__submenu{
    opacity: 1;
    visibility: visible;
}
/* sizes */
.dropdown-menu--md{
    width: 100%;
    min-width: calc(247rem/16);
    padding: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(44rem/16) calc(56rem/16) calc(44rem/16) calc(44rem/16);
        max-width: calc(304rem/16);
    }
}
.dropdown-menu--sm{
    min-width: calc(247rem/16);
    padding: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(22rem/16);
        max-width: calc(304rem/16);
    }
}

/* button styles */
.dropdown__btn-title {
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        letter-spacing: 0.2px;
    }
}
.dropdown-toggle:after,
.dropdown__btn-title:after {
    font-family: iconfont;
    content: var(--icon-chevron);
    font-size: calc(5rem/16);
    transition: transform 200ms ease-in-out;
}
.dropdown__btn-title:after {
    margin-left: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        margin-left: calc(8rem / 16);
    }
}
.dropdown__btn.show .dropdown__btn-title:after{
    color: var(--color-primary);
    transform: rotate(180deg);
}

.dropdown-toggle:after {
    border: 0;
    position: absolute;
    right: calc(22rem / 16);
    top: 50%;
    transform: translateY(-50%);
}
.dropdown-toggle__svg,
.dropdown-toggle__svg > svg{
    max-height: calc(72rem / 16);
    max-width: calc(72rem / 16);
    height: 100%;
    width: 100%;
}