.hero-half{
    background-color: var(--color-light);
}
.hero-half__content{
    padding: calc(22rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem/16) 0;
    }
}
.hero-half__backlink{
    color: var(--color-grey-50);
    line-height: calc(18/15);
    letter-spacing: calc(0.5rem/16);
}
.hero-half__backlink-icon{
    color: var(--color-default);
    font-size: calc(8rem/16);
}
.hero-half__date{
    line-height: calc(26/15);
    color: var(--color-dark-grey);
    margin-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(47rem/16);
    }
}
.hero-half__title{
    margin-top: calc(11rem/16);
}
.hero-half__subtitle{
    margin-top: calc(44rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(70rem/16);
    }
}
.hero-half__subtitle + .hero-half__title{
    margin-top: calc(22rem/16);
}
.hero-half__intro{
    font-size: calc(15rem/16);
    line-height: calc(25/15);
    margin-top: calc(22rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        line-height: calc(28/18);
    }
}
.hero-half__intro:last-child{
    margin-top: calc(44rem/16);
}
.hero-half__sub-intro{
    font-size: calc(13rem/16);
    line-height: calc(20/13);
    color: var(--color-grey-50);
    margin-top: calc(11rem/16);
    @media screen and (min-width: 768px){
        line-height: calc(24/13);
        margin-top: calc(22rem/16);
    }
}
.hero-half__footnote{
    line-height: calc(26/15);
    color: var(--color-dark-grey);
    margin-top: calc(11rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(22rem/16);
    }
}
.hero-half__bg{
    min-height: calc(250rem/16);
    @media screen and (min-width: 768px){
        min-height: calc(540rem/16);
    }
}
.hero-half__bg-img{
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.hero-half__copy{
    position: absolute;
    bottom: calc(11rem/16);
    right: calc(11rem/16);
    width: calc(15rem/16);
    height: calc(15rem/16);
    line-height: calc(12/15);
}

/*dark mode*/
.hero-half.hero-half--dark{
    background-color: var(--color-dark);
    color: var(--color-light);
}
.hero-half--dark .hero-half__backlink-icon{
    color: var(--color-grey-50);
}
.hero-half--dark .hero-half__backlink:hover{
    color: #FFF;
}
