.file-input {
    display: block;
    font-size: calc(13rem / 16);
}
.file-input__box {
    height: calc(66rem / 16);
    border: calc(1rem / 16) dashed rgba(158,158,159,0.3);;
    border-radius: calc(4rem / 16);
    padding: calc(22rem / 16) calc(16rem / 16) calc(22rem / 16) calc(46rem / 16);
    display: grid;
    grid-template-columns: auto min-content min-content;
    align-items: center;
}
.is-dragging .file-input__box {
    border-color: var(--color-primary);
}
.file-input__box-text {
    overflow: hidden;
    text-overflow: ellipsis;
}
.file-input__box-button {
    font-size: calc(12rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: calc(6rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    line-height: 1;
    position: relative;
    margin-left: auto;
}
.file-input__box-button:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: calc(22rem / 16);
    width: calc(1rem / 16);
    background: rgba(158,158,159,0.3);;
}
.file-input__box-reset {
    background: none;
    border: 0;
    font-size: calc(12rem / 16);
    line-height: 1;
    color: var(--color-grey-50);
}

.file-input.is-disabled {
    opacity: .5;
    pointer-events: none;
}