.compare-item{
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
    font-size: calc(12rem/16);
    position: relative;
    padding: calc(11rem/16);
    padding-right: calc(30rem / 16);
    display: grid;
    grid-gap: calc(11rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: 27% 1fr;
        font-size: calc(13rem/16);
    }

    @media screen and (min-width: 1400px){
        grid-gap: calc(22rem / 16);
        font-size: calc(15rem/16);
    }
}
.compare-item__content-title {
    font-size: calc(13rem/16);
    line-height: calc(18/15);
    letter-spacing: normal;
    @media screen and (min-width: 1600px){
        font-size: calc(15rem/16);
    }
}
.compare-item__delete{
    position: absolute;
    right: calc(8rem / 16);
    top: calc(8rem / 16);
    font-size: calc(17rem/16);
}