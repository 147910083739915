.nav-tabs .nav-link{
    padding: 0 0 calc(6rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    line-height: calc(22/15);
    letter-spacing: calc(0.5rem/16);
    position: relative;
    text-align: center;
}
.nav-tabs .nav-item + .nav-item{
    margin-left: calc(22rem/16);

    @media screen and (min-width: 768px) {
        margin-left: calc(44rem/16);
    }
}
.nav-tabs .nav-link:before{
    background-color: var(--color-primary);
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(44rem / 16);
    height: calc(2rem/16);
    transform: scaleX(0) translateX(-50%);
    transform-origin: left;
    transition: transform 200ms ease-in-out;
}
.nav-tabs .nav-link.active:before{
    transform: scaleX(1) translateX(-50%);
}
