.scrolling-area {
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    @media screen and (max-width: 767px) {
        scrollbar-width: none;
    }
}
.scrolling-area::-webkit-scrollbar {
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.scrolling-area--btn {
    margin-bottom: calc(-10rem / 16);
    margin-top: calc(-10rem / 16);
    padding-top: calc(10rem / 16);
    padding-bottom: calc(10rem / 16);
}
.scrolling-area--vertical {
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (min-width: 768px) {
        padding: 0;
        padding-right: calc(7rem/16);
        margin: 0;
    }
}
.scrolling-area::-webkit-scrollbar-track {
    background-color: var(--color-light-grey);
    transition: all 120ms ease;
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
}

.scrolling-area--xs::-webkit-scrollbar,
.scrolling-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: calc(4rem / 16);
    height: calc(4rem / 16);
    background: #FFF;
    transition: all 120ms ease;
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
}

.scrolling-area--xs::-webkit-scrollbar-thumb,
.scrolling-area::-webkit-scrollbar-thumb {
    background-color: var(--color-grey-50);
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
    opacity: 0.5;
    transition: all 120ms ease;
}
.scrolling-area__item {
    scroll-snap-align: start;
}
.scrolling-area__item:first-child {
    scroll-snap-align: center;
}
.scrolling-area__item--fill {
    width: 100%;
    flex-shrink: 0;
}
@media screen and (max-width: 767px) {
    .scrolling-area--xs {
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scrollbar-width: none;
    }
    .scrolling-area--xs::-webkit-scrollbar {
        display: none;
    }

    .container .scrolling-area--fill-xs {
        margin-left: calc(-22rem / 16);
        margin-right: calc(-22rem / 16);
        padding-left: calc(22rem / 16);
        padding-right: calc(22rem / 16);
    }
    .scrolling-area__item--fill-xs {
        width: 100%;
        flex-shrink: 0;
    }

    .scrolling-area--vertical-xs {
        overflow-y: auto;
        overflow-x: hidden;

        @media screen and (min-width: 768px) {
            padding: 0;
            padding-right: calc(7rem/16);
            margin: 0;
        }
    }
}

/* scroll slider */
.scrolling-area.scrolling-area--slider {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    scrollbar-width: none;
}
.scrolling-area--slider::-webkit-scrollbar {
    display: none;
}
.scrolling-area--slider .scrolling-area__item {
    scroll-snap-align: start;
}

/* teaser slider */
.teaser-slider .scrolling-area {
    scroll-padding: calc(40rem / 16);
    padding: calc(20rem / 16) calc(40rem / 16);
    margin: calc(-20rem / 16) 0;

    @media screen and (min-width: 768px) {
        scroll-padding: clamp(20px, 100vw, calc(50vw - calc(870rem / 16)));
        padding: calc(20rem / 16) clamp(20px, 100vw, calc(50vw - calc(870rem / 16)));
    }
}
.teaser-slider:not(.teaser-slider--no-animation) .scrolling-area__item {
    @media screen and (min-width: 768px){
        opacity: .5;
    }
    transition: opacity 200ms ease-in-out;
}
.teaser-slider:not(.teaser-slider--no-animation) .scrolling-area__item.is-active {
    opacity: 1;
}
.teaser-slider .scroll-slider__arrow {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

/* teaser slider in small content */
.main-content__content-sm .teaser-slider .scrolling-area {
    scroll-padding: calc(40rem / 16);
    padding: calc(20rem / 16) calc(40rem / 16) calc(20rem / 16) 0;
    margin: calc(-20rem / 16) calc(-22rem / 16) calc(-20rem / 16) calc(-11rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) 0;
        margin: calc(-20rem / 16) calc(-11rem / 16);
    }
}

/* reference slider */
.reference-slider__track .scroll-slider__arrow[disabled]{
    display: none;
}
.reference-slider__track .scroll-slider__arrow {
    bottom: calc(44rem/16);
}

/* teaser slider */
.magazine-teaser-slider .scrolling-area {
    scroll-padding: calc(18rem / 16);
    padding: calc(20rem / 16) calc(18rem / 16);
    margin: calc(-20rem / 16) 0;

    @media screen and (min-width: 768px) {
        scroll-padding: clamp(20px, 100vw, calc(50vw - calc(870rem / 16)));
        padding: calc(20rem / 16) clamp(20px, 100vw, calc(50vw - calc(870rem / 16)));
    }
}
.magazine-teaser-slider .scrolling-area__item {
    @media screen and (min-width: 768px){
        opacity: .5;
    }
    transition: opacity 200ms ease-in-out;
}
.magazine-teaser-slider .scrolling-area__item.is-active {
    opacity: 1;
}
.magazine-teaser-slider .scroll-slider__arrow {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

/* arrows */
.scroll-slider__arrow {
    position: absolute;
    z-index: 10;
    bottom: calc(10rem / 16);
    right: calc(10rem / 16);
    width: calc(32rem / 16);
    height: calc(32rem / 16);
    border-radius: 50%;
    padding: 0;
    background: #fff;
    color: var(--color-default);
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.2);
    border: 0;
    font-size: calc(8rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.scroll-slider__arrow.scroll-slider__arrow--prev {
    left: calc(10rem / 16);
    right: auto;
}
.scroll-slider__arrow.scroll-slider__arrow--prev .scroll-slider__arrow-icon {
    transform: rotate(-180deg);
}
.scroll-slider__arrow[disabled] {
    pointer-events: none;
}
.scroll-slider__arrow[disabled] .scroll-slider__arrow-icon{
    opacity: .3;
}
.scroll-slider__arrow:hover,
.scroll-slider__arrow:focus:not(:focus-visible) {
    outline: none;
    color: var(--color-primary);
}

.scroll-slider--hide-arrows .scroll-slider__arrow {
    display: none;
}

/* arrow positions */
.scroll-slider--arrows-center .scroll-slider__arrow {
    bottom: auto;
    top: 45%;
}
.scroll-slider--arrows-center .scroll-slider__arrow[disabled] {
    opacity: 0;
}
/*arrow lg size*/
.scroll-slider--arrows-lg .scroll-slider__arrow{
    width: calc(44rem/16);
    height: calc(44rem/16);
}

/* arrow colors */
.scroll-slider--arrows-dark .scroll-slider__arrow {
    background: var(--color-default);
    color: #fff;
}
.scroll-slider--arrows-dark .scroll-slider__arrow:hover {
    background: var(--color-primary);
    color: #fff;
}
.scroll-slider--arrows-dark .scroll-slider__arrow:focus:not(:focus-visible) {
    outline: none;
    color: #fff;
}

/*arrows plain*/
.scroll-slider--arrows-plain .scroll-slider__arrow{
    box-shadow: none;
    background: none;
    font-size: calc(12rem/16);
    color: #FFF;
    text-shadow: 0 calc(1rem / 16) calc(3rem / 16) rgba(0, 0, 0, .4);
    transition: color 200ms ease-in-out;
}
.scroll-slider--arrows-plain .scroll-slider__arrow:hover {
    color: var(--color-primary);
}

/* dots */
.scroll-slider__dot-container {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}
.scroll-slider__dot-container--bottom {
    top: auto;
    bottom: calc(5rem / 16);
    right: 0;
}
.scroll-slider__dot {
    width: calc(8rem / 16);
    height: calc(8rem / 16);
    border-radius: 50%;
    background: var(--color-light-grey);
    border: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    pointer-events: auto;
    flex-shrink: 0;
}
.scroll-slider__dot.is-active {
    background: var(--color-primary);
}