.brand-teaser{
    padding: calc(5rem/16) calc(5rem/16);
    background-color: #FFF;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    position: relative;

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(10rem/16);
    }
}

.brand-teaser:not(.brand-teaser--light):before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);

}
.brand-teaser:hover:before {
    opacity: 1;
}

.brand-teaser__img{
    max-height: calc(56rem/16);
    width: auto;
    @media screen and (min-width: 768px){
        max-height: calc(80rem/16);
    }
}
.brand-slider .swiper {
    padding: calc(20rem/16) 0 calc(20rem/16) clamp(20px,100vw,calc(50vw - 54.375rem));;
}

/* variants */
.brand-teaser--max {max-width: calc(266rem / 16); width: 100%;}
.brand-teaser--light {background: var(--color-light);}


/* sizes */
.brand-teaser--lg {
    @media screen and (min-width: 1200px) {
        padding: calc(24rem / 16);
    }
}
.brand-teaser--lg .brand-teaser__img{
    @media screen and (min-width: 768px){
        max-height: calc(100rem/16);
    }
}