.download-item {
    background: #fff;
    padding: calc(11rem / 16);
    position: relative;
    font-size: calc(13rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
        font-size: calc(15rem / 16);
    }
}
.download-item + .download-item {
    margin-top: calc(6rem / 16);
}
.download-item__title{
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem / 16);
}
.download-item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.download-item:hover:before {
    opacity: 1;
}

.download-item__icon {
    vertical-align: -0.1em;
    font-size: calc(16rem / 16);
}
.download-item__img{
    width: calc(100rem/16);
    @media screen and (min-width: 768px){
        width: calc(181rem/16);
    }
}
.download-item__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
}

.download-item__bottom {
    line-height: 1.15;
}