.positions-table {
    margin-top: calc(22rem / 16);
    max-height: calc(380rem / 16);
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    @media screen and (min-width: 768px) {
        max-height: calc(614rem / 16);
    }
}
.positions-table::-webkit-scrollbar {
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.positions-table::-webkit-scrollbar-track {
    background-color: var(--color-light-grey);
    transition: all 120ms ease;
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
}
.positions-table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: calc(10rem / 16);
    height: calc(10rem / 16);
    background: #FFF;
    transition: all 120ms ease;
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
}
.positions-table::-webkit-scrollbar-thumb {
    background-color: var(--color-grey-50);
    border-top-left-radius: calc(6rem/16);
    border-top-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
    opacity: 0.5;
    transition: all 120ms ease;
}
.positions-table__head {
    display: grid;
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(7, calc(100rem / 16)) calc(193rem / 16);
    background: var(--color-light);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    grid-gap: calc(11rem / 16);
    padding: calc(11rem / 16);
    color: var(--color-dark-grey);
    border-radius: calc(4rem / 16);
    position: sticky;
    top: 0;
    z-index: 1;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(40rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }
}
.positions-table__body-row {
    display: grid;
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(7, calc(100rem / 16)) calc(193rem / 16);
    grid-gap: calc(11rem / 16);
    padding: calc(11rem / 16);
    border-bottom: calc(1rem / 16) solid var(--color-light);

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(40rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }
}

.positions-table__errors {
    display: grid;
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(7, calc(100rem / 16)) calc(193rem / 16);
    grid-gap: calc(11rem / 16);
    border-radius: calc(6rem / 16);
    padding: calc(6rem / 16) calc(11rem / 16);
    position: sticky;
    top: calc(45rem / 16);
    z-index: 1;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(40rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }

    @media screen and (min-width: 1400px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(6, calc(114rem / 16)) calc(193rem / 16);
    }
}
.positions-table__arrow-btn {
    background: none;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    border: calc(1rem / 16) solid var(--color-light-grey);
    border-radius: calc(4rem / 16);
    padding: calc(2rem / 16);
    color: #fff;
    font-size: calc(5rem / 16);
}

.positions-table__body,
.positions-table__head,
.positions-table__errors {
    min-width: calc(1270rem / 16);

    @media screen and (min-width: 768px) {
        min-width: calc(1450rem / 16);
    }
}

/* post forming */
.positions-table--post-forming .positions-table__head,
.positions-table--post-forming .positions-table__body-row,
.positions-table--post-forming .positions-table__errors {
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(4, calc(100rem / 16)) calc(30rem / 16) repeat(3, calc(100rem / 16)) calc(194rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(40rem / 16) calc(85rem / 16) 1fr repeat(3, calc(114rem / 16)) calc(30rem / 16) repeat(3, calc(114rem / 16)) calc(194rem / 16);
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(3, calc(114rem / 16)) calc(30rem / 16) repeat(3, calc(114rem / 16)) calc(194rem / 16);
    }
}


/* webfront */
.positions-table--sm .positions-table__body,
.positions-table--sm .positions-table__head,
.positions-table--sm .positions-table__errors  {
    min-width: calc(800rem / 16);

    @media screen and (min-width: 768px) {
        min-width: calc(950rem / 16);
    }
}
.positions-table--sm .positions-table__head {
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(4, calc(100rem / 16)) calc(96rem / 16);
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(3, calc(114rem / 16)) calc(96rem / 16);
    }
}

.positions-table--sm .positions-table__body-row {
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(4, calc(100rem / 16)) calc(96rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(3, calc(114rem / 16)) calc(96rem / 16);
    }
}
.positions-table--sm .positions-table__errors {
    grid-template-columns: calc(40rem / 16) calc(85rem / 16) repeat(4, calc(100rem / 16)) calc(96rem / 16);

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(90rem / 16) calc(85rem / 16) 1fr repeat(3, calc(114rem / 16)) calc(96rem / 16);
    }
}


/* wood configurator */
.positions-table--wood-configurator {
    font-size: calc(12rem / 16);
}
.positions-table--wood-configurator .positions-table__body,
.positions-table--wood-configurator .positions-table__head,
.positions-table--wood-configurator .positions-table__errors  {
    min-width: calc(1450rem / 16);
}
.positions-table--wood-configurator .positions-table__head {
    height: calc(45rem / 16);
    grid-template-columns: calc(30rem/16) calc(160rem / 16) repeat(8, calc(95rem / 16)) calc(150rem / 16) 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(30rem/16) calc(160rem / 16) repeat(8, calc(95rem / 16)) calc(160rem / 16) 1fr;
    }
}

.positions-table--wood-configurator .positions-table__body-row {
    row-gap: calc(2rem / 16);
    grid-template-columns: calc(30rem/16) calc(160rem / 16) repeat(8, calc(95rem / 16)) calc(150rem / 16) 1fr;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(30rem/16) calc(160rem / 16) repeat(8, calc(95rem / 16)) calc(160rem / 16) 1fr;
    }
}
.positions-table--wood-configurator .positions-table__errors {
    grid-template-columns: 1fr;
}
.positions-table--wood-configurator .price-row {
    font-size: calc(12rem / 16);
    letter-spacing: normal;

    +.price-row {
        margin-top: calc(4rem / 16);
    }
}
.positions-table--wood-configurator .form-select option:disabled:not(.form-select__placeholder) {
    display: none;
}

.positions-table__body-row-errors {
    grid-column-start: 1;
    grid-column-end: 11;
    padding: 0;
}