.content-block + .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(66rem/16);
}

.content-block-sm + .content-block-sm,
.content-block + .content-block-sm,
.content-block + .pimcore_area_content > .content-block-sm,
.pimcore_area_content + .pimcore_area_content > .content-block-sm {
    margin-top: calc(22rem/16);
}

.content-block-md + .content-block-md,
.content-block + .content-block-md,
.content-block + .pimcore_area_content > .content-block-md,
.pimcore_area_content + .pimcore_area_content > .content-block-md {
    margin-top: calc(22rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(45rem/16);
    }
}

.pimcore_area_content:first-child > .content-block-sm {
    margin-top: calc(-44rem/16);
}

@media screen and (max-width: 767px) {
    .main-content.content-block {
        margin-top: calc(20rem / 16);
    }
}