.overview-checkout__summary{
    transition: background-color 200ms ease-in-out, border-top-width 200ms ease-in-out, box-shadow 200ms ease-in-out;
    padding-bottom: calc(33rem/16);
    z-index: 10;
}
.overview-checkout__summary.is-affix{
    background-color: #FFF;
    box-shadow: 0 calc(-10rem/16) calc(20rem/16) 0 rgba(62,61,64,0.1);
}
.overview-checkout__summary.is-affix .summary{
    border-top: none;
}