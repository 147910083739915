.anchor-nav {
    font-size: calc(11rem / 16);
    line-height: calc(13/11);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    color: var(--color-grey-50);
    letter-spacing: normal;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        line-height: calc(13/14);
    }
}
.anchor-nav__content {
    position: relative;
    background: var(--color-light);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    padding: calc(8rem / 16) 0 calc(8rem / 16) calc(10rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) calc(5rem / 16);
    }
}
@media screen and (max-width: 767px) {
    .anchor-nav__content:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: calc(20rem / 16);
        background: linear-gradient(to right, rgba(247,247,247,0) 0%,rgba(247,247,247,1) 100%);
        pointer-events: none;
    }
    .anchor-nav__list {
        padding-right: calc(20rem / 16);
    }
}
.anchor-nav__title {
    padding: calc(5rem / 16) 0;
    padding-right: calc(4rem / 16);
    margin-right: calc(26rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16) calc(18rem / 16);
        padding-right: calc(45rem / 16);
    }

    @media screen and (max-width: 767px) {
        max-width: 31%;
    }
}
.anchor-nav__title:after {
    content: '';
    position: absolute;
    right: 0;
    height: calc(22rem / 16);
    width: calc(1rem / 16);
    top: 50%;
    transform: translateY(-50%);
    background: var(--color-grey-50);
}

.anchor-nav__title,
.anchor-nav__link {
    flex-shrink: 0;
    color: var(--color-grey-50);
}
.anchor-nav__link {
    font-size: calc(13rem / 16);
    padding: calc(5rem / 16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(8rem / 16) calc(18rem / 16);
    }
}
.anchor-nav__link.active,
.anchor-nav__link:hover {
    color: var(--color-default);
}
.anchor-nav__link-icon {
    font-size: calc(5rem / 16);
    margin-right: calc(6rem / 16);
    vertical-align: 0.3em;
    transition: color 150ms ease-in-out;
}
.anchor-nav__link.active .anchor-nav__link-icon,
.anchor-nav__link:hover .anchor-nav__link-icon {
    color: var(--color-primary);
}

.anchor-nav__item {
    scroll-snap-align: start;
}
.anchor-nav__item + .anchor-nav__item {
    margin-left: calc(10rem / 16);
}

/* smalll anchor nav in navbar */
.anchor-nav--inline {
    @media screen and (min-width: 768px) {
        padding: 0 calc(22rem / 16);
    }
}
.anchor-nav--inline .anchor-nav__title{
    padding-left: 0;
}
.anchor-nav--inline .anchor-nav__content {
    padding: calc(8rem / 16) 0;
    box-shadow: none;
    width: 100%;
    max-width: calc(1706rem / 16);
    margin: 0 auto;
    position: relative;
}
.anchor-nav--inline .anchor-nav__content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: .3;
    border-top: calc(1rem / 16) solid var(--color-grey-50);
}