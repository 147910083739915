.cart-item{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    box-shadow: 0 0 20px 0 rgba(62,61,64,0.1);
    padding: calc(22rem/16);
}
.cart-item__title{
    line-height: calc(18/13);
}
.cart-item__address{
    line-height: calc(15/13);
}
.cart-item__grid{
    display: grid;
    grid-gap: calc(11rem/16);
    flex-grow: 1;
}
.cart-item--four-col{
    grid-template-columns: 1fr 1fr;
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1fr 30% 0.7fr min-content;
    }
    @media screen and (min-width: 1200px){
        grid-template-columns: 1fr 1fr 37% 0.7fr min-content;
    }

}
.cart-item--six-col{
    grid-template-columns:1fr 1fr 2fr 2.5fr 2fr 1.5fr min-content;
}