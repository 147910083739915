.accordion-item{
    border: none;
    background: #FFF;
}
.accordion-item + .accordion-item{
    margin-top: calc(6rem/16);
}
.accordion-button{
    border: none;
    outline: 0;
    box-shadow: none;
    background: #FFF;
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    padding: calc(24rem/16) calc(35rem / 16) calc(24rem / 16) calc(22rem/16);
}
.accordion-button:focus{
    outline: none;
    box-shadow: none;
    border: none;
}
.accordion-button:after{
    content: none;
}
.collapsed.accordion-button:before{
    content: var(--icon-plus);
    font-size: calc(10rem/16);
}
.accordion-button:before{
    position: absolute;
    right: calc(22rem/16);
    background-color: var(--color-dark);
    background-image: none;
    content: var(--icon-minus);
    font-family: iconfont;
    border-radius: 50%;
    font-size: calc(10rem/16);
    width: calc(22rem/16);
    height: calc(22rem/16);
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.accordion-button:not(.collapsed){
    background: #FFF;
    color: var(--color-primary);
    outline: none;
    box-shadow: none;
}
.accordion-body{
    border: none;
    padding-top: 0;
    color: var(--color-dark-grey);
}

/* sizes */
.accordion-item--sm .accordion-button {
    text-transform: none;
    letter-spacing: normal;
    color: var(--color-dark-grey);
    padding: calc(14rem/16) calc(25rem / 16) calc(14rem / 16) calc(12rem/16);
    font-size: calc(13rem / 16);
    //padding: calc(24rem/16) calc(35rem / 16) calc(24rem / 16) calc(22rem/16);
    //font-size: calc(15rem/16);

    //@media screen and (min-width: 786px) {
    //    font-size: calc(13rem / 16);
    //    padding: calc(14rem/16) calc(25rem / 16) calc(14rem / 16) calc(12rem/16);
    //}

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem/16);
        padding: calc(24rem/16) calc(35rem / 16) calc(24rem / 16) calc(22rem/16);
    }
}
.accordion-item--sm .accordion-button:before {
    content: var(--icon-chevron);
    background: none;
    width: auto;
    height: auto;
    color: currentColor;
    font-size: calc(7rem / 16);
}
.accordion-item--sm .accordion-body {
    font-size: calc(13rem / 16);
}

@media screen and (max-width: 767px) {
    .collapse--tabs-content{
        @media screen and (max-width: 767px){
            padding: calc(22rem/16);
            background-color: #FFF;
        }
    }
    .collapse--tabs-pane.fade.tab-pane > .collapse.collapse--tabs-container:not(.show){
        display: none;
    }
    .collapse--tabs-pane.fade.tab-pane{
        display: block;
        opacity: 1;
    }
}
@media screen and (min-width: 768px){
    .collapse--tabs-pane.tab-pane.fade.active.show > .collapse.collapse--tabs-container{
        display: block;
    }
}

/* if accordion has no body content hide arrow */
.accordion-button--no-content {
    pointer-events: none;
}
.accordion-button.accordion-button--no-content:before {
    content: none;
}