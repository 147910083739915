.download-teaser{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.download-teaser__content{
    padding: calc(16rem/16) calc(22rem/16) calc(22rem/16) calc(22rem/16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    @media screen and (min-width: 768px){
        padding: calc(22rem/16);
    }
}
.download-teaser__title{
    font-size: calc(18rem/16);
    line-height: calc(23/18);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    color: var(--color-dark);
}