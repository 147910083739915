.hotspots__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateZ(0);
}
.hotspots__img {
    object-fit: cover;
    @media screen and (min-width: 768px) {
        border-radius: calc(6rem / 16);
    }
}


.hotspot {
    position: absolute;
    z-index: 1;
    width: calc(44rem / 16);
    height: calc(44rem / 16);
}
.hotspot.is-open {
    z-index: 2;
}
.hotspot__btn {
    width: calc(44rem / 16);
    height: calc(44rem / 16);
    border-radius: 50%;
    background: rgba(0,0,0,.3);
    border: 0;
}
.hotspot__btn:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 calc(4rem / 16) calc(10rem / 16) 0 rgba(62,61,64,0.2);
    transform: translateX(-50%) translateY(-50%);
}

.hotspot__detail {
    position: fixed;
    left: calc(10rem / 16);
    background: #fff;
    padding: calc(11rem / 16);
    width: calc(355rem / 16);
    max-width: calc(100vw - calc(20rem / 16));
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.1);
    grid-template-columns: 30% 57% 1fr;
    grid-gap: calc(10rem / 16);
    opacity: 0;
    visibility: hidden;
    transition: transform 200ms ease-in-out;

    @media screen and (min-width: 768px) {
        position: absolute;
        left: calc(100% - calc(2rem / 16));
        top: calc(100% - calc(2rem / 16));
        width: calc(422rem / 16);
    }
}
.hotspot__detail--product {
    grid-template-columns: 20% 67% 1fr;
}
.hotspot--detail-left .hotspot__detail {
    @media screen and (min-width: 768px) {
        left: auto;
        right: calc(100% - calc(2rem / 16));
    }
}
.hotspot--detail-top .hotspot__detail {
    @media screen and (min-width: 768px) {
        top: auto;
        bottom: calc(100% - calc(2rem / 16));
    }
}
.is-open > .hotspot__detail {
    opacity: 1;
    visibility: visible;
}
.hotspot__detail-title {
    letter-spacing: calc(1rem / 16);
    line-height: calc(24/18);
    text-transform: none;
    font-size: calc(12rem / 16);

    @media screen and(min-width: 768px){
        font-size: calc(18rem / 16);
    }
}
.hotspot__detail-price {
    font-size: calc(12rem / 16);
}
.hotspot__detail-value {
    font-size: calc(15rem / 16);
}

.hotspot__detail-img .copyright-info {
    right: calc(6rem/16);
    top: calc(6rem/16);
}