.media-area__description{
    color: var(--color-dark-grey);
    font-size: calc(13rem/16);
    margin: 0 calc(22rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        margin: 0;
    }
}
.media-area__title{
    margin: calc(22rem/16);
    @media screen and (min-width: 768px){
        margin-left: 0;
    }
}
.media-area__copyright{
    position: absolute;
    right: 0;
    top: 0;
    height: fit-content;
    width: fit-content;
    color: #fff;
    z-index: 1;
}