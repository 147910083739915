.main-nav--level-3 {
    @media screen and (min-width: 768px) {
        position: absolute;
        left: 100%;
        padding: calc(20rem / 16) 0 calc(20rem / 16) calc(20rem / 16);
        background-color: var(--color-light);
        width: calc(460rem / 16);
        max-width: 25vw;
        top: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
        transition-delay: 150ms;
        border-left: calc(1rem / 16) solid var(--color-grey);
    }

    @media screen and (min-width: 768px) and (min-height: 850px) {
        padding: calc(90rem / 16) 0 calc(90rem / 16) clamp(20px, 12%, 90px);
    }

}
@media screen and (min-width: 768px) {
    .main-nav__item:hover > .main-nav--level-3,
    .is-open > .main-nav--level-3 {
        opacity: 1;
        visibility: visible;
    }
}