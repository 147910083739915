.jobs-list__header{
    display: flex;
    align-items: center;
    height: calc(66rem/16);
    padding: 0 calc(22rem/16);
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    color: var(--color-dark-grey);
}
.jobs-list__item{
    @media screen and (min-width: 768px){
        height: calc(88rem/16);
        padding: 0 calc(22rem/16);
    }
    @media screen and (min-width: 1200px){
        display: flex;
        align-items: center;
    }
}
.jobs-list__row{
    padding: calc(11rem/16) calc(11rem/16);
    position: relative;
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px){
        padding: 0;
    }
}
.jobs-list__row:hover{
    background-color: var(--color-light-grey);
}
.jobs-list__row.jobs-list__row-border:last-child:after{
    display: none;
}
.jobs-list__row:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-radius: calc(6rem/16);
    background-color: var(--color-grey-50);
    opacity: 0.3;
}
.jobs-list__item-description{
    color: var(--color-grey-50);
    font-size: calc(11rem/16);
    line-height: calc(13/11);
    @media screen and (min-width: 768px){
        display: none;
    }
}