.form-errors {
    font-size: calc(12rem / 16);
    line-height: calc(14/12);
    margin-top: calc(6rem / 16);
}
.form-errors.invalid-feedback {
    display: block;
}
.form-errors:empty,
.is-valid .form-errors {
    display: none;
}
.form-errors li + li {
    margin-top: calc(5rem / 16);
}