.vertical-gutter {
    //margin-top: calc(-1 * var(--bs-gutter-y));
    display: flex;
    flex-direction: column;
    grid-gap: var(--bs-gutter-y);
}
//.vertical-gutter__item {
//    margin-top: var(--bs-gutter-y);
//    padding-top: 0.1px; /* fix vertical gutter in vertical gutter :D */
//}


.gy-base {
    --bs-gutter-y: calc(11rem / 16);

    @media screen and(min-width: 768px){
        --bs-gutter-y: calc(22rem / 16);
    }
}
.gy-md-base {
    @media screen and(min-width: 768px){
        --bs-gutter-y: calc(22rem / 16);
    }
}