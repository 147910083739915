.link-arrow {
    border: 0;
    width: calc(22rem / 16);
    height: calc(22rem / 16);
    flex-shrink: 0;
    border-radius: 50%;
    background: var(--color-primary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8rem / 16);
    transition: background 200ms ease-in-out;
}
.link-arrow--dark,
.text-primary .link-arrow {
    background: var(--color-dark);
}
.link-arrow:hover,
.link-arrow:focus {
    color: #fff;
}

.link-arrow.collapse:not(.collapsed) > .icon-arrow{
    transform: rotate(-90deg);
}
.link-arrow.collapse > .icon-arrow{
    transition: transform 200ms ease-in-out;
}