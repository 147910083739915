.social-list__title{
    color: var(--color-dark-grey);
    line-height: calc(26/15);
    margin-bottom: calc(11rem/16);
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}
.social-list__container{
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 1600px){
        justify-content: flex-start;
    }
}
.social-list__item{
    width: calc(44rem/16);
    height: calc(44rem/16);
    border-radius: 50%;
    border: calc(1rem/16) solid var(--color-light-grey);
    padding: calc(11rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: calc(22rem/16);
}
.social-list__item + .social-list__item{
    margin-left: calc(5rem/16);
    @media screen and (min-width: 1600px){
        margin-left: calc(22rem/16);
    }
}
.social-list__item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0 calc(10rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: 50%;
}
.social-list__item:hover:before {
    opacity: 1;
}