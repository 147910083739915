.form-check {
    min-height: unset;
    margin-bottom: 0;
    display: flex;
}
.form-check.form-check-inline {
    display: inline-flex;
}
.form-check-label {
    margin-top: auto;
    margin-bottom: auto;
}
.form-check-input {
    width: calc(18rem / 16);
    height: calc(18rem / 16);
    flex-shrink: 0;
    border: calc(1rem / 16) solid var(--color-dark-grey);
    margin-right: calc(10rem / 16);
}
.form-check--box {
    position: relative;
    padding: 0;
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    letter-spacing: calc(1rem / 16);
    flex-shrink: 1;
    margin-bottom: 0;
    font-size: calc(12rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.form-check--box .form-check-input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.form-check--box .form-check-label{
    padding: calc(6rem / 16) calc(10rem / 16);
    background: #fff;
    color: var(--color-default);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    border-radius: calc(4rem / 16);
    cursor: pointer;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        padding: calc(20rem / 16) calc(22rem / 16);
    }
}
.form-check--box .form-check-input:checked + .form-check-label{
    background: var(--color-primary);
    color: #fff;
}
.form-check--box .form-check-input:checked + .form-check-label .form-check__count {
    color: #fff;
}
.form-check--box .form-check-input:disabled + .form-check-label{
    pointer-events: none;
}

.form-check--box-outlined .form-check-input:checked + .form-check-label{
    background: #fff;
    color: var(--color-primary);
    border: calc(1rem / 16) solid var(--color-primary);
}

.form-check__count {
    margin-left: calc(8rem / 16);
    color: var(--color-grey-50);
}

/* box only */
.form-check--hidden-label {
    padding-left: 0;
}
.form-check--hidden-label .form-check-input {
    margin: 0;
}

/* color checkbox */
.form-check--color .form-check-input {
    width: calc(44rem / 16);
    height: calc(33rem / 16);
    border-radius: calc(4rem / 16);
    border-color: var(--color-light-grey);
}
.form-check--color .form-check-input:checked[type=checkbox] {
    background-image: none;
    border-color: var(--color-primary);
}

/* image background */
.form-check--img .form-check__img  {
    border-radius: calc(4rem / 16);
}
.form-check--img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .7;
    background: var(--color-light);
    border-radius: calc(4rem / 16);
}
.form-check--img .form-check__overlay-text {
    position: relative;
    z-index: 1;
}

/* switch */
.form-switch {
    padding-left: 0;
    position: relative;
}
.form-switch__state {
    position: absolute;
    left: 0;
    top: calc(5rem / 16);
    height: calc(12rem / 16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        top: calc(7rem / 16);
    }
}
.form-switch__state .form-check-input {
    margin: 0;
    float: none;
}
.form-switch .form-check-label {
    padding-left: calc(37rem / 16);
    cursor: pointer;
}
.form-switch .form-check-input {
    height: calc(12rem / 16);
}
.form-switch .form-check-input,
.form-switch .form-check-input:focus,
.form-switch .form-check-input:checked {
    background: var(--color-grey);
    border-color: var(--color-grey);
}
.form-switch__state-circle {
    position: absolute;
    top: calc(-2rem / 16);
    left: 0;
    border-radius: 50%;
    width: calc(16rem / 16);
    height: calc(16rem / 16);
    background: #fff;
    box-shadow: 0 calc(2rem / 16) calc(4rem / 16) 0 rgba(62,61,64,0.4);
    transition: background 200ms ease-in-out, transform 200ms ease-in-out;
    pointer-events: none;
}
.form-switch:hover .form-switch__state-circle {
    background: var(--color-primary);
}
.form-check-input:checked + .form-switch__state-circle {
    background: var(--color-primary);
    transform: translateX(calc(100% - 3px));
}


.form-check-area {
    position: relative;
}
.form-check-area .form-check-label:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
}
.form-check-area .form-check-input[disabled] ~ .form-check-label:after {
    pointer-events: none;
}
.form-check-label__img--sm {
    max-width: calc(122rem / 16);
    margin: auto;
}