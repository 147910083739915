:root {
    --color-primary: #E95B10;
    --color-secondary: var(--color-dark);
    --color-text-default: var(--color-default);
    --color-text-muted: var(--color-grey-50);

    --color-default: var(--color-dark);
    --color-white: #FFF;
    --color-light: #F7F7F7;
    --color-light-grey: #EFEFEF;
    --color-grey: #e0e0e0;
    --color-grey-50: #9E9E9F;
    --color-dark-grey: #5F6062;
    --color-dark: #3E3D40;

    --color-success: #70B77E;
    --color-info: #FFC107;
    --color-warning: #ffc107;
    --color-info-blue: #17A2B8;
    --color-danger: #C64C4C;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #e64b00;
    --color-secondary-dark: #424D56;
    --color-success-dark: #12862B;
    --color-info-dark: #967102;
    --color-warning-dark: #967102;
    --color-danger-dark: #cc2333;
    --color-light-dark: #e4e8ec;
    --color-dark-dark: #14191E;
    --color-white-dark: #F8F9FA;
    --color-default-dark: #1f232a;

    --color-primary-light: #f56113;
    --color-secondary-light: #6E8090;
    --color-success-light: #1EE048;
    --color-info-light: #FBBD03;
    --color-warning-light: #FBBD03;
    --color-danger-light: #e15260;
    --color-light-light: #fff;
    --color-dark-light: #687F96;
    --color-white-light: #FFF;
    --color-default-light: #3b4552;

    --font-default: "Inter-Regular", sans-serif;
    --font-default-bold: "Inter-Bold", sans-serif;
    --font-default-light: "Inter-Light", sans-serif;
    --font-weight-light: 400;
    --font-weight-default: 400;
    --font-weight-bold: 400;

    --font-size-default: calc(15rem / 16);
}
