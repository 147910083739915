/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-transform-none {
    text-transform: none;
}


/* Text Color Helper */
.text-white { color: #ffffff; }
.text-dark { color: var(--color-dark); }
.text-color-default { color: var(--color-text-default); }
.text-grey-50 { color: var(--color-grey-50); }
.text-dark-grey { color: var(--color-dark-grey); }
.text-primary-hover:hover {color: var(--color-primary);}


/* Background Color Helper */
.bg-dark-transparent {background: rgba(62, 61, 64, 0.8);}
.bg-light {color: var(--color-text-default)}
.bg-dark-grey {background: var(--color-dark-grey)}

/* Additional */
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    cursor: pointer;
}

.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.link-with-arrow {
    letter-spacing: calc(.5rem / 16);
}
.link-with-arrow__icon {
    font-size: calc(8rem / 16);
    vertical-align: 0.1em;
}
svg.link-with-arrow__icon {
    height: calc(8rem / 16);
}
.clickable {cursor: pointer}

.padding-box {
    padding: calc(11rem / 16);

    @media screen and (min-width: 768px){
        padding: calc(22rem / 16);
    }
    @media screen and (min-width: 1400px){
        padding: calc(44rem / 16);
    }
}
.padding-box--sm {
    padding: calc(11rem / 16);

    @media screen and (min-width: 768px){
        padding: calc(22rem / 16);
    }
    @media screen and (min-width: 1400px){
        padding: calc(22rem / 16);
    }
}

/* mobile sticky bar */
.mobile-sticky-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.mobile-sticky-bar__btn {
    height: calc(66rem / 16);
    width: 100%;
    border-radius: 0;
}


.sticky-bottom-bar {
    z-index: 10;
    transition: background 200ms ease-in-out;
}
.sticky-bottom-bar.is-affix {
    background: #fff;
    padding: calc(11rem / 16) 0;
    box-shadow: 0 0 calc(10rem / 16) calc(2rem / 16) rgba(0,0,0,.2);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16) 0;
    }
}


/* category icons */
.category-icon--lg{
    width: calc(66rem/16);
}
.category-icon{
    width: calc(54rem/16);
}

.caption {
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    height: auto;
    width: auto;
    background: var(--color-dark);
    color: #fff;
    border-top-left-radius: calc(6rem / 16);
    font-size: calc(10rem / 16);
    padding: calc(2rem / 16) calc(10rem / 16);
}
@media screen and (min-width: 768px) {
    .caption--lg {
        font-size: calc(12rem / 16);
        padding: calc(5rem / 16) calc(12rem / 16) calc(2rem / 16);
    }
}

.copyright-info{
    background: none;
    border: none;
    color: var(--color-default);
    line-height: 1;
    position: absolute;
    right: calc(11rem/16);
    top: calc(11rem/16);
    width: calc(14rem / 16);
    z-index: 2;
    height: auto;
    bottom: auto;
    left: auto;
    text-shadow: 0 0 calc(4rem / 16) rgba(255,255,255,.6);
}
.copyright-info.text-white {
    text-shadow: 0 0 calc(4rem / 16) rgba(0,0,0,.6);
}

.tooltip--lg .tooltip-inner {
    padding: calc(10rem / 16);
    font-size: calc(12rem / 16);
}