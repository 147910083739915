.grid-list {
    font-size: calc(13rem / 16);
}
.grid-list__item {
    grid-template-columns: 45% 1fr 30%;
    grid-gap: calc(6rem / 16);
}
.grid-list__item + .grid-list__item {
    padding-top: calc(8rem / 16);
    margin-top: calc(8rem / 16);
    border-top: calc(1rem / 16) solid var(--color-grey);
}