.teaser {
    background: #fff;
    font-size: calc(13rem / 16);
    line-height: calc(24/13);
    position: relative;
}

@media screen and (min-width: 768px) {
    .teaser:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        border-radius: calc(6rem / 16);
    }
    .teaser:hover:before {
        opacity: 1;
    }
}
.teaser__content {
    padding: calc(24rem / 16) calc(22rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(45rem / 16);
    }
}
.teaser__content-text {
    margin-top: calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        margin-top: calc(24rem / 16);
    }
}
.teaser__content-title {
    font-size: calc(15rem / 16);
    line-height: calc(29/24);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }

    @media screen and (min-width: 1200px) {
        letter-spacing: calc(1.5rem / 16);
        font-size: calc(24rem / 16);
    }
}
.teaser__btn--icon {
    @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
    }
}

.teaser__bg-img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (min-width: 768px) {
        border-top-right-radius: calc(6rem / 16);
        border-bottom-right-radius: calc(6rem / 16);
    }
}

/* sizes */
.teaser--sm .teaser__content-title {
    text-transform: none;
}

.teaser.teaser--lg {
    line-height: calc(24/13);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
        line-height: calc(26/15);
    }
}
.teaser--lg .teaser__content-text {
    @media screen and (min-width: 768px) {
        margin-top: calc(10rem / 16);
    }
}
.teaser--lg .teaser__content-title {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        line-height: calc(23/18);
    }
}