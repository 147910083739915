html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    overflow-x: hidden;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
    }
    .container.container--no-padding-xs {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
    .container .container__fill-xs {
        margin: 0 calc(-22rem / 16);
    }

    .row:not([class*=" gx-"]) {
        --bs-gutter-x: calc(11rem / 16)
    }
}
.container.container {
    max-width: calc(1752rem/16); /* 1708px + padding */
    width: 100%;
    padding-left: calc(22rem / 16);
    padding-right: calc(22rem / 16);
}
.container.container-narrow {
    max-width: calc(884rem/16); /* 840px + padding */
}
.container-narrow{
    max-width: calc(840rem/16);
    width: 100%;
}
.container.container-medium {
    max-width: calc(1175rem/16); /* 1131px + padding */
}
.container-medium{
    max-width: calc(1131rem/16);
    width: 100%;
}

@media screen and (min-width: 768px) {
    .container.container--move-right {
        max-width: calc(1730px + (100vw - 1730px) / 2);
        padding-left: calc(22rem / 16);
        padding-right: 0;
        margin-left: auto;
        margin-right: 0;
    }
}

/* fix container in container */
.container .container {
    padding-left: 0;
    padding-right: 0;
}