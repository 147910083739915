.list-group{
    max-height: calc(583rem/16);
    margin-left: calc(7rem/16);
}
.list-group-item{
    border-radius: calc(6rem / 16);
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    position: relative;
    min-height: calc(88rem/16);
    display: flex;
    align-items: center;
    overflow: hidden;
}
.list-group-item + .list-group-item{
    margin-top: calc(11rem/16);
}
.list-group-item + .list-group-item.active{
    margin-top: calc(11rem/16);
}
.list-group-item.active:before{
    content: '';
    position: absolute;
    width: calc(4rem/16);
    height: 100%;
    left: 0;
    top: 0;
    background-color: var(--color-primary);
}