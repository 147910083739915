.datepicker:after {
    content: var(--icon-calendar);
    font-family: iconfont;
    position: absolute;
    font-size: calc(12rem / 16);
    right: calc(12rem / 16);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.datepicker__reset {
    position: absolute;
    right: calc(2rem / 16);
    top: calc(2rem / 16);
    background: #fff;
    bottom: calc(2rem / 16);
    z-index: 1;
    border: 0;
    color: var(--color-text-muted);
    font-size: calc(12rem / 16);
    padding: calc(2rem / 16) calc(12rem / 16) calc(2rem / 16) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}
.datepicker__reset:hover,
.datepicker__reset:focus {
    outline: none;
    color: var(--color-primary);
}
.form-control--datepicker:not(:placeholder-shown) ~ .datepicker__reset {
    visibility: visible;
}