.impression-grid{
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
    }
}

.impression-grid .impression-grid__left-area{
    margin-right: 5px;
    @media screen and (min-width: 768px){
        margin-right: 10px;
    }
}

.impression-grid__left-area{
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px){
        width: 45%;
    }
}

.impression-grid__left-area .impression-grid__item{
    margin-bottom: 5px;
    @media screen and (min-width: 768px){
        margin-bottom: 10px;
    }
}

.impression-grid__left-area .impression-grid__item:last-of-type{
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}

.impression-grid__right-area{
    width: 100%;
    display: grid;
    grid-gap: 5px;
    @media screen and (min-width: 768px){
        grid-gap: 10px;
        width: 55%;
        grid-template-areas:
    "bigRight bigRight bigRight"
    "mediumRight smallRight ."
    ;
    }
    grid-template-columns: 50% 25% 1fr;
    grid-template-areas:
    "bigRight bigRight bigRight"
    "mediumRight smallRight smallRight"
;
}

.impression-grid__item img{
    border-radius: calc(6rem/16);
}
.impression-grid__item{

}

.impression-grid__item-one{
    height: 25%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.impression-grid__item-one .title-block{
   margin-bottom: 0;
}

.impression-grid__item-two{
    grid-area: bigRight;
}

.impression-grid__item-three{

}

.impression-grid__item-four{
    grid-area: mediumRight;
}

.impression-grid__item-five{
grid-area: smallRight;
}

.impression-grid__item-six{
    width: 100%;
    @media screen and (min-width: 768px){
        width: 50%;
        margin-left: auto;
        margin-right: 0;

    }
}

.impression-grid__item-link{
    margin-left: calc(10rem/16);
    margin-top: calc(10rem/16);
    @media screen and (min-width: 768px){
        margin-left: calc(32rem/16);
        margin-top: calc(16rem/16);
    }
    font-size: var(--font-size-default);
}

.impression-grid__item-link-text{
   color: var(--color-primary);
    font-family: var(--font-default-bold);
font-weight: var(--font-weight-bold);
}
