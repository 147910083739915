.blurbs__icon{
    font-size: calc(45rem/16);
    color: var(--color-primary);
    height: calc(55rem/16);
}
.blurbs__text{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    color: var(--color-dark-grey);
}
.blurbs__title{
    font-size: calc(15rem/16);
}
.blurbs{
    max-width: calc(300rem/16);
    @media screen and (min-width: 768px){
        max-width: calc(460rem/16);
    }
}