.price-row {
    //grid-template-columns: 50% 1fr;
    grid-template-columns: auto 1fr;
    grid-gap: calc(4rem / 16);
    letter-spacing: calc(-.5rem / 16);
    font-size: calc(13rem / 16);
    line-height: calc(22/13);
    word-break: normal;
}
.price-row + .price-row {
    margin-top: calc(3rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(8rem / 16);
    }
}
.price-row__value {
    font-size: calc(15rem / 16);
    line-height: 1;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    grid-gap: calc(4rem / 16);
}

div:not(.price-row--highlight) .price-row {
    line-height: normal;
}

.price-row__suffix {
    font-size: calc(12rem / 16);
}
.price-row--lg .price-row__value {
    font-size: calc(20rem / 16);
}
.price-row--lg .price-row__suffix {
    font-size: calc(15rem / 16);
}
.price-row--highlight .price-row__value {
    color: var(--color-primary);
}
.price-row--sm .price-row__value {
    color: var(--color-grey-50);
    font-size: calc(13rem / 16);
}
.price-row--sm .price-row__suffix {
    color: var(--color-grey-50);
    font-size: calc(10rem / 16);
}

/* loading */
.price-placeholder {
    position: relative;
    background: linear-gradient(315deg, #FCFCFC 0%, #EEEEEE 57.99%, #F7F7F7 69.59%, #FCFCFC 100%);
    height: calc(20rem / 16);
    border-radius: calc(10rem / 16);
    animation: loadingBar 1.5s linear infinite;
    max-width: 100%;
    width: calc(144rem / 16);
}
.price-placeholder + .price-placeholder{
    margin-top: calc(12rem / 16);
}
.price-placeholder--sm {
    height: calc(16rem / 16);
    width: calc(137rem / 16);
}
@keyframes loadingBar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -144px 0;
    }
}

/* multiple prices */
.multiple-price {
    display: flex;
    flex-direction: column;
    gap: calc(4rem / 16);
}
.price-row--lg .multiple-price {
    font-size: calc(19rem / 16);
}
.multiple-price__sm {
    font-size: calc(12rem / 16);
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}