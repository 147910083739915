.people-teaser{
    background-color: #FFF;
    padding: calc(11rem/16);
}
.people-teaser__content{
    font-size: calc(13rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(14rem/16);
        margin-bottom: calc(14rem/16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(15rem/16);
    }
}
.people-teaser__name{
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem/16);
    color: var(--color-dark);
    font-family: var(--font-default-bold);
}
.people-teaser__job{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
    color: var(--color-dark-grey);
    @media screen and (min-width: 786px){
        font-size: calc(15rem/16);
        line-height: calc(26/15);
    }
}
.people-teaser__link{
    font-family: var(--font-default-bold);
    line-height: calc(26/15);
    color: var(--color-primary);
}
.people-teaser__contact{
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        display: block;
    }
    @media screen and (min-width: 1200px){
        display: flex;
    }
}