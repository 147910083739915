:root {
    --main-navbar-height--xs: calc(60rem / 16);
}
@media screen and (max-width: 767px) {
    .main-nav {
        display: block;
        position: fixed;
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--color-light);
        color: var(--color-default);
        margin: 0;
    }
    .main-nav__top {
        height: var(--main-navbar-height--xs);
        background: var(--color-light);
        box-shadow: 0 10px 20px 0 rgba(62,61,64,0.1);
        z-index: 2;
        position: relative;
        padding: calc(10rem / 16) calc(22rem / 16);
        display: flex;
        justify-content: space-between;
    }

    .main-nav--level-0 {
        z-index: 11;
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    }
    .is-open > .navbar .main-nav--level-0 {
        opacity: 1;
        visibility: visible;
    }
    .main-nav__list--level-0 {
        overflow: auto;
        overflow-x: hidden;
        height: calc(100% - calc(75rem / 16));
        padding: calc(56rem / 16) calc(44rem / 16) calc(75rem / 16);
    }

    .main-nav:not(.main-nav--level-0) {
        z-index: 1;
        transform: translateX(100%);
        transition: transform 200ms ease-in-out;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: calc(56rem / 16) calc(44rem / 16) calc(66rem / 16);
    }
    .is-locked.main-nav {
        overflow: hidden;
    }
    .is-open > .main-nav {
        transform: translateX(0);
    }
    .main-nav--level-1 {
        top: var(--main-navbar-height--xs);
    }

    /* toggle button */
    .navbar__toggle {
        background: none;
        border: 0;
        position: relative;
    }

    .navbar__toggle-line {
        height: calc(1.5rem / 16);
        width: calc(16rem / 16);
        background: currentColor;
        display: block;
        margin-left: auto;
        transition: transform 200ms ease-in-out;
    }
    .navbar__toggle-line + .navbar__toggle-line {
        margin-top: calc(4rem / 16);
    }
    .navbar__toggle-line:nth-child(2) {
        transform-origin: right center;
        transform: scaleX(0.7);
    }

    .main-nav__item {
        display: block;
        width: 100%;
    }
    .main-nav__title-link,
    .main-nav__item-content {
        padding: calc(2rem / 16) 0;
        text-align: left;
    }

    /* level 0 */
    .main-nav__item--level-0 + .main-nav__item--level-0 {
        margin-top: calc(35rem / 16);
    }
    .main-nav__item-content--level-0 {
        letter-spacing: calc(.5rem / 16);
        font-size: calc(15rem / 16);
    }
    .is-open > .main-nav__item-content--level-0 {
        color: var(--color-primary);
    }
    .main-nav__item-content--level-0 > .main-nav__item-arrow {
        transform: rotate(-90deg);
    }


    /* Level 1 */
    .main-nav__item--level-1 + .main-nav__item--level-1 {
        margin-top: calc(10rem / 16);
    }

    .main-nav__bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .main-nav__bottom-btn {
        border-radius: 0;
        width: 100%;
        padding: calc(20rem / 16);
    }
    .main-nav__back {
        font-size: calc(14rem / 16);
        color: var(--color-grey-50);
    }
    .main-nav__back-icon {
        color: var(--color-default);
        font-size: calc(8rem / 16);
        margin-right: calc(6rem / 16);
    }


    .nav-list .nav-list__dropdown {
        z-index: 11;
    }
    .nav-list__dropdown-close {
        position: absolute;
        right: calc(10rem / 16);
        top: calc(10rem / 16);
        padding: calc(10rem / 16);
    }
}