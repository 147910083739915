.state-circle {
    width: calc(10rem / 16);
    height: calc(10rem / 16);
    border-radius: 50%;
    display: inline-block;
    flex-shrink: 0;
    background: var(--color-primary);
}

.state-circle--success, .state-circle--green-stockinfo2, .state-circle--green {
    background: var(--color-success);
}
.state-circle--warning, .state-circle--grey, .state-circle--orange {
    background: var(--color-warning);
}
.state-circle--danger {
    background: var(--color-danger);
}

/* with text */
.state-circle--with-text {
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: calc(12rem / 16);
}

/* text state colors  */
.state-color--default {color: var(--color-text-default);}
.state-color--orange {color: var(--color-primary);}
.state-color--grey {color: var(--color-text-muted);}