.subsidiary-item__img{
    max-width: calc(654rem/16);
    width: 100%;
}
.subsidiary-item__subtitle{
    margin-top: calc(22rem/16);
    margin-bottom: calc(22em/16);
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
        margin-bottom: calc(13rem/16);
    }
}