.hero {
    position: relative;
    border-radius: calc(6rem / 16);
    overflow: hidden;
}
.hero__content {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    padding: calc(20rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(25rem / 16) calc(45rem / 16) calc(35rem / 16);
    }

    @media screen and (min-width: 1400px) {
        padding: calc(66rem / 16) calc(66rem / 16) calc(30rem / 16);
    }
}

.hero__text > p {
    @media screen and (min-width: 768px) {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

/* overlay colors */
.hero__content--overlay:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .7;
    z-index: -1;
}
.hero__content--overlay-dark:before {
    background: linear-gradient(90deg, #000 0%, rgba(0,0,0,0) 100%);
}
.hero__content--overlay-light {
    color: var(--color-default);
}
.hero__content--overlay-light:before {
    background: linear-gradient(90deg, #fff 0%, rgba(255,255,255,0) 100%);
}

/* content positions */
.hero__content--right.hero__content--overlay:before {transform: scaleX(-1);}
.hero__content--right .hero__title {
    margin-left: auto;
}

/* hero sizes */
.hero--sm {
    min-height: calc(260rem / 16);
    padding: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        min-height: calc(320rem / 16);
        padding: calc(66rem / 16);
    }
}
.hero--sm .hero__bg {
    height: 100%;
}

.hero--md {
    color: #fff;
    min-height: calc(220rem / 16);
    padding: calc(30rem / 16) 0 0;
    overflow: visible;

    @media screen and (min-width: 768px) {
        padding: calc(110rem / 16) 0 0;
        min-height: calc(570rem / 16);
    }
}
.hero__bottom {
    position: relative;
    z-index: 1;
}
.hero__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: calc(20rem / 16);
    right: 0;
    z-index: -1;

    @media screen and (min-width: 768px) {
        bottom: calc(30rem / 16);
    }
}
.hero__bg:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.6;
    border-radius: calc(6rem / 16);
    background: linear-gradient(326.15deg, rgba(233,91,16,0) 0%, var(--color-primary) 100%);
}
.hero__bg--dark:after {
    background: linear-gradient(90deg, #000000 0%, rgba(0,0,0,0) 100%);
}
.hero__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}