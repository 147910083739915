.citation__text{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    color: var(--color-dark-grey);
    line-height: calc(24/20);
    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        line-height: calc(32/24);
    }
}
.citation__content{
    padding-left: calc(9rem/16);
    padding-top: calc(22rem/16);
    position: relative;
    z-index: 2;
    @media screen and (min-width: 768px){
        padding-left: calc(20rem/16);
    }
}
.citation__icon{
    font-size: calc(44rem/16);
    position: absolute;
    top: calc(-19rem/16);
    left: calc(-12rem/16);
    width: calc(50rem/16);
    height: calc(50rem/16);
    color: var(--color-light-grey);
    @media screen and (min-width: 768px){
        top: calc(-12rem/16);
        left: 0;
    }
}
.citation__author{
    margin-top: calc(11rem/16);
    font-size: calc(13rem/16);
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px){
        margin-top: calc(22rem/16);
        font-size: calc(15rem/16);
    }
}

/*citation teaser*/
.citation__img{
    @media screen and (min-width: 768px){
        padding: 0 calc(40rem/16);
    }
}