.btn.payment-select__btn{
    border: calc(1.5rem/16) solid var(--color-dark-grey);
    padding: calc(22rem/16);
    height: calc(66rem/16);
}
.payment-select__btn:focus,
.payment-select__btn:focus-visible{
    outline: 0;
    box-shadow: none;
}
.payment-select.is-submitted .payment-select__btn,
.payment-select.is-active .payment-select__btn{
    border-color: var(--color-success);
}
.payment-select__icon{
    display: none;
}
.payment-select:not(.is-active):not(.is-submitted) {
    opacity: 0.3;
}
.payment-select.is-submitted .payment-select__icon,
.payment-select.is-active .payment-select__icon{
    display: block;
}
.payment-select__img{
    max-width: calc(58rem/16);
}