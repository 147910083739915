.wysiwyg-with-media__content{
    @media screen and (max-width: 767px){
        padding: 0 calc(22rem/16);
        margin-top: calc(22rem/16);
    }
}
.wysiwyg-with-media__content.wysiwyg-with-media__content-sm-left{
    @media screen and (min-width: 768px){
        padding-right: calc(44rem/16);
    }
}
.wysiwyg-with-media__content.wysiwyg-with-media__content-sm-right{
    @media screen and (min-width: 768px){
        padding-left: calc(44rem/16);
    }

}