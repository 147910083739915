.timeline{
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.timeline__item{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    color: var(--color-text-default);
    font-size: calc(12rem/16);
    line-height: 1;
    margin-top: calc(4rem/16);
}
.timeline__item:last-child:before{
    background-color: var(--color-light);
}
.timeline__item:before{
    content: '';
    position: absolute;
    background-color: var(--color-primary);
    height: calc(2rem/16);
    top: calc(7rem/16);
    right: calc(-999rem/16);
    left: 50%;
    pointer-events: none;
}
.timeline__item-title {
    text-align: center;
    margin-top: calc(5rem / 16);
    max-width: calc(200rem / 16);
}
.timeline__node{
    background-color: var(--color-primary);
    border-radius: 50%;
    width: calc(16rem/16);
    height: calc(16rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.timeline__node-icon{
    font-size: calc(4rem/16);
    color: #FFF;
}

/*active states*/
.timeline__item.is-active {
    color: var(--color-dark);
    font-family: var(--font-default-bold);
}
.timeline__item.is-active:not(:last-child):not(.is-complete) .timeline__node-icon,
.timeline__item.is-disabled:not(:last-child) .timeline__node-icon{
    display: none;
}
.timeline__item.is-active:not(:last-child):not(.is-complete):before,
.timeline__item.is-disabled:not(:last-child):before,
.timeline__item.is-disabled .timeline__node{
    background-color: var(--color-grey);
}
.timeline__item.is-disabled {
    color: var(--color-grey);
}
.timeline__item.is-disabled {pointer-events: none;}
.timeline__item:last-child .timeline__node{
    background-color: var(--color-light);
}
.timeline__item:last-child .timeline__node-icon{
    font-size: calc(8rem/16);
    color: var(--color-grey);
}
.timeline__item:last-child.is-active .timeline__node-icon{
    color: var(--color-primary);
}