.img-teaser__image-container:before{
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(192.19deg, rgba(0,0,0,0) 0%, #000000 100%);
    opacity: 0.5;
    border-radius: calc(6rem/16);
    transition: opacity 200ms ease-in-out;
}
.img-teaser__image-container:after {
    background: linear-gradient(206.57deg, rgba(233,91,16,0) 0%, var(--color-primary) 100%);
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0;
    border-radius: calc(6rem/16);
    transition: opacity 200ms ease-in-out;
}
.img-teaser:hover .img-teaser__image-container:after{
    opacity: 0.7;
}
.img-teaser:hover .img-teaser__image-container:before{
    opacity: 0;
}
.img-teaser__image{
    z-index: -1;
}
.img-teaser__content{
    position: absolute;
    inset: 0;
    color: #FFF;
    padding: calc(11rem/16);
    display: flex;
    justify-content: end;
    flex-direction: column;

    @media screen and (min-width: 768px){
        padding: calc(22rem/16);
    }
}
.img-teaser__title,.img-teaser__icon{
    font-size: calc(15rem/16);
}

.img-teaser .img-teaser__content--min-height{
    min-height: 80px;
    justify-content: flex-start;
}
