.price-box-container {
    width: 100%;

    @media screen and (min-width: 768px) {
        max-width: calc(454rem / 16);
        margin-left: auto;
        min-height: unset !important;
        height: 0;
    }
}
.price-box {
    padding: calc(11rem / 16);
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    font-size: calc(13rem / 16);
    line-height: calc(22/13);

    @media screen and (min-width: 768px){
        padding: calc(22rem / 16);
    }
    @media screen and (min-width: 1400px){
        padding: calc(44rem / 16);
    }
}

.price-box--sticky {
    @media screen and (min-width: 768px){
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
}

.price-box--sm {
    @media screen and (min-width: 1400px){
        padding: calc(22rem / 16);
    }
}