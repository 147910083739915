.quicklink {
    height: 100%;
    background: #fff;
    padding: calc(22rem / 16);
    position: relative;
    font-family: var(--font-default-light);
    font-weight: var(--font-weight-light);

    @media screen and (min-width: 1400px) {
        padding: calc(33rem / 16) calc(44rem / 16);
    }
}
.quicklink__title{
    font-size: calc(15rem/16);
    line-height: calc(22/18);
    text-transform: uppercase;

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem/16);
    }
}
.quicklink:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.quicklink:hover:before {
    opacity: 1;
}

/*quicklink small*/
.quicklink--sm .quicklink__title,
.quicklink--xs .quicklink__title{
    letter-spacing: calc(1rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
        line-height: calc(20/15);
    }
}
.quicklink.quicklink--xs{
    padding: calc(24rem / 16) calc(22rem / 16) calc(20rem / 16);
    @media screen and (min-width: 768px){
        padding: calc(24rem / 16) calc(22rem / 16) calc(20rem / 16);
    }
}

/* with image */
.quicklink.quicklink--img {
    grid-gap: calc(22rem / 16);
    padding: calc(11rem/16);
    box-shadow: 0 10px 20px 0 rgba(62,61,64,0.1);
}
.quicklink__img {
    max-width: calc(115rem / 16);
}
.quicklink--img .quicklink__title {
    text-transform: none;
}
.quicklink--img .link-arrow {
    background: var(--color-dark);
}