@font-face {
    font-family: "iconfont";
    src: url('static/icons/font/iconfont.woff2') format('woff2'),
    url('static/icons/font/iconfont.woff') format('woff'),
    url('static/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-arbeitsklima:before { content: "\EA01" }
.icon-arrow:before { content: "\EA02" }
.icon-artikel:before { content: "\EA03" }
.icon-ausbilder:before { content: "\EA04" }
.icon-ausbildung:before { content: "\EA05" }
.icon-bestellungen:before { content: "\EA06" }
.icon-bewerben:before { content: "\EA07" }
.icon-bike-leasing:before { content: "\EA08" }
.icon-boden:before { content: "\EA09" }
.icon-bullet:before { content: "\EA0A" }
.icon-calendar:before { content: "\EA0B" }
.icon-cart:before { content: "\EA0C" }
.icon-category-decorative:before { content: "\EA0D" }
.icon-category:before { content: "\EA0E" }
.icon-change-material:before { content: "\EA0F" }
.icon-change:before { content: "\EA10" }
.icon-check:before { content: "\EA11" }
.icon-chevron:before { content: "\EA12" }
.icon-close:before { content: "\EA13" }
.icon-company:before { content: "\EA14" }
.icon-compare-outline:before { content: "\EA15" }
.icon-compare:before { content: "\EA16" }
.icon-decimal-quantity:before { content: "\EA17" }
.icon-dekorfinder:before { content: "\EA18" }
.icon-delete:before { content: "\EA19" }
.icon-delivery:before { content: "\EA1A" }
.icon-document:before { content: "\EA1B" }
.icon-download:before { content: "\EA1C" }
.icon-duplicate:before { content: "\EA1D" }
.icon-eap:before { content: "\EA1E" }
.icon-edit:before { content: "\EA1F" }
.icon-elements:before { content: "\EA20" }
.icon-ellipsis-v:before { content: "\EA21" }
.icon-events-mitarbeiter:before { content: "\EA22" }
.icon-events:before { content: "\EA23" }
.icon-facebook:before { content: "\EA24" }
.icon-filter:before { content: "\EA25" }
.icon-flexibilitaet:before { content: "\EA26" }
.icon-flexibles-arbeiten:before { content: "\EA27" }
.icon-furnier:before { content: "\EA28" }
.icon-gesundheitsfoerderung:before { content: "\EA29" }
.icon-gg:before { content: "\EA2A" }
.icon-gratisobst:before { content: "\EA2B" }
.icon-hands:before { content: "\EA2C" }
.icon-help:before { content: "\EA2D" }
.icon-holzbau:before { content: "\EA2E" }
.icon-home:before { content: "\EA2F" }
.icon-ideenmanagement:before { content: "\EA30" }
.icon-image:before { content: "\EA31" }
.icon-info-filled:before { content: "\EA32" }
.icon-info:before { content: "\EA33" }
.icon-instagram:before { content: "\EA34" }
.icon-job-finden:before { content: "\EA35" }
.icon-kennenlernen:before { content: "\EA36" }
.icon-kvh:before { content: "\EA37" }
.icon-lagersortiment:before { content: "\EA38" }
.icon-lagerstruktur:before { content: "\EA39" }
.icon-lehre-matura:before { content: "\EA3A" }
.icon-like:before { content: "\EA3B" }
.icon-linkedin:before { content: "\EA3C" }
.icon-lkw:before { content: "\EA3D" }
.icon-location:before { content: "\EA3E" }
.icon-logo:before { content: "\EA3F" }
.icon-logout:before { content: "\EA40" }
.icon-ma-empfehlung:before { content: "\EA41" }
.icon-mail:before { content: "\EA42" }
.icon-minus:before { content: "\EA43" }
.icon-niederlassung:before { content: "\EA44" }
.icon-no:before { content: "\EA45" }
.icon-nosrew:before { content: "\EA46" }
.icon-note:before { content: "\EA47" }
.icon-notification:before { content: "\EA48" }
.icon-onboarding:before { content: "\EA49" }
.icon-onlinetools:before { content: "\EA4A" }
.icon-parking:before { content: "\EA4B" }
.icon-paste:before { content: "\EA4C" }
.icon-pause:before { content: "\EA4D" }
.icon-phone:before { content: "\EA4E" }
.icon-pinterest:before { content: "\EA4F" }
.icon-platte:before { content: "\EA50" }
.icon-play:before { content: "\EA51" }
.icon-plus:before { content: "\EA52" }
.icon-praemien:before { content: "\EA53" }
.icon-print:before { content: "\EA54" }
.icon-products:before { content: "\EA55" }
.icon-profile:before { content: "\EA56" }
.icon-progress:before { content: "\EA57" }
.icon-pruefen:before { content: "\EA58" }
.icon-raumstudio:before { content: "\EA59" }
.icon-safe:before { content: "\EA5A" }
.icon-sale:before { content: "\EA5B" }
.icon-saleprice:before { content: "\EA5C" }
.icon-screw:before { content: "\EA5D" }
.icon-search:before { content: "\EA5E" }
.icon-spin:before { content: "\EA5F" }
.icon-start:before { content: "\EA60" }
.icon-stockcontrol-auslaufware:before { content: "\EA61" }
.icon-stockcontrol-bestellware:before { content: "\EA62" }
.icon-stockcontrol-lagersortiment:before { content: "\EA63" }
.icon-terrasse:before { content: "\EA64" }
.icon-touren:before { content: "\EA65" }
.icon-tree:before { content: "\EA66" }
.icon-tuer:before { content: "\EA67" }
.icon-turn:before { content: "\EA68" }
.icon-twitter:before { content: "\EA69" }
.icon-uebergreifende-ausbildung:before { content: "\EA6A" }
.icon-upload:before { content: "\EA6B" }
.icon-verfuegbarkeit:before { content: "\EA6C" }
.icon-verguenstigungen:before { content: "\EA6D" }
.icon-vielfalt:before { content: "\EA6E" }
.icon-webcut-nut:before { content: "\EA6F" }
.icon-webcut:before { content: "\EA70" }
.icon-weiterbildungsangebote:before { content: "\EA71" }
.icon-whatsapp:before { content: "\EA72" }
.icon-wood:before { content: "\EA73" }
.icon-xing:before { content: "\EA74" }
.icon-youtube:before { content: "\EA75" }
.icon-zentrallager:before { content: "\EA76" }
.icon-zoomin:before { content: "\EA77" }
.icon-zoomout:before { content: "\EA78" }


:root {
--icon-arbeitsklima: "\EA01";
    --icon-arrow: "\EA02";
    --icon-artikel: "\EA03";
    --icon-ausbilder: "\EA04";
    --icon-ausbildung: "\EA05";
    --icon-bestellungen: "\EA06";
    --icon-bewerben: "\EA07";
    --icon-bike-leasing: "\EA08";
    --icon-boden: "\EA09";
    --icon-bullet: "\EA0A";
    --icon-calendar: "\EA0B";
    --icon-cart: "\EA0C";
    --icon-category-decorative: "\EA0D";
    --icon-category: "\EA0E";
    --icon-change-material: "\EA0F";
    --icon-change: "\EA10";
    --icon-check: "\EA11";
    --icon-chevron: "\EA12";
    --icon-close: "\EA13";
    --icon-company: "\EA14";
    --icon-compare-outline: "\EA15";
    --icon-compare: "\EA16";
    --icon-decimal-quantity: "\EA17";
    --icon-dekorfinder: "\EA18";
    --icon-delete: "\EA19";
    --icon-delivery: "\EA1A";
    --icon-document: "\EA1B";
    --icon-download: "\EA1C";
    --icon-duplicate: "\EA1D";
    --icon-eap: "\EA1E";
    --icon-edit: "\EA1F";
    --icon-elements: "\EA20";
    --icon-ellipsis-v: "\EA21";
    --icon-events-mitarbeiter: "\EA22";
    --icon-events: "\EA23";
    --icon-facebook: "\EA24";
    --icon-filter: "\EA25";
    --icon-flexibilitaet: "\EA26";
    --icon-flexibles-arbeiten: "\EA27";
    --icon-furnier: "\EA28";
    --icon-gesundheitsfoerderung: "\EA29";
    --icon-gg: "\EA2A";
    --icon-gratisobst: "\EA2B";
    --icon-hands: "\EA2C";
    --icon-help: "\EA2D";
    --icon-holzbau: "\EA2E";
    --icon-home: "\EA2F";
    --icon-ideenmanagement: "\EA30";
    --icon-image: "\EA31";
    --icon-info-filled: "\EA32";
    --icon-info: "\EA33";
    --icon-instagram: "\EA34";
    --icon-job-finden: "\EA35";
    --icon-kennenlernen: "\EA36";
    --icon-kvh: "\EA37";
    --icon-lagersortiment: "\EA38";
    --icon-lagerstruktur: "\EA39";
    --icon-lehre-matura: "\EA3A";
    --icon-like: "\EA3B";
    --icon-linkedin: "\EA3C";
    --icon-lkw: "\EA3D";
    --icon-location: "\EA3E";
    --icon-logo: "\EA3F";
    --icon-logout: "\EA40";
    --icon-ma-empfehlung: "\EA41";
    --icon-mail: "\EA42";
    --icon-minus: "\EA43";
    --icon-niederlassung: "\EA44";
    --icon-no: "\EA45";
    --icon-nosrew: "\EA46";
    --icon-note: "\EA47";
    --icon-notification: "\EA48";
    --icon-onboarding: "\EA49";
    --icon-onlinetools: "\EA4A";
    --icon-parking: "\EA4B";
    --icon-paste: "\EA4C";
    --icon-pause: "\EA4D";
    --icon-phone: "\EA4E";
    --icon-pinterest: "\EA4F";
    --icon-platte: "\EA50";
    --icon-play: "\EA51";
    --icon-plus: "\EA52";
    --icon-praemien: "\EA53";
    --icon-print: "\EA54";
    --icon-products: "\EA55";
    --icon-profile: "\EA56";
    --icon-progress: "\EA57";
    --icon-pruefen: "\EA58";
    --icon-raumstudio: "\EA59";
    --icon-safe: "\EA5A";
    --icon-sale: "\EA5B";
    --icon-saleprice: "\EA5C";
    --icon-screw: "\EA5D";
    --icon-search: "\EA5E";
    --icon-spin: "\EA5F";
    --icon-start: "\EA60";
    --icon-stockcontrol-auslaufware: "\EA61";
    --icon-stockcontrol-bestellware: "\EA62";
    --icon-stockcontrol-lagersortiment: "\EA63";
    --icon-terrasse: "\EA64";
    --icon-touren: "\EA65";
    --icon-tree: "\EA66";
    --icon-tuer: "\EA67";
    --icon-turn: "\EA68";
    --icon-twitter: "\EA69";
    --icon-uebergreifende-ausbildung: "\EA6A";
    --icon-upload: "\EA6B";
    --icon-verfuegbarkeit: "\EA6C";
    --icon-verguenstigungen: "\EA6D";
    --icon-vielfalt: "\EA6E";
    --icon-webcut-nut: "\EA6F";
    --icon-webcut: "\EA70";
    --icon-weiterbildungsangebote: "\EA71";
    --icon-whatsapp: "\EA72";
    --icon-wood: "\EA73";
    --icon-xing: "\EA74";
    --icon-youtube: "\EA75";
    --icon-zentrallager: "\EA76";
    --icon-zoomin: "\EA77";
    --icon-zoomout: "\EA78";
    
}