.lg-icon {
    font-family: 'iconfont' !important;
}
.lg-prev:after {
    content: var(--icon-arrow);
    transform: rotate(180deg);
    display: inline-block;
}
.lg-next:before {
    content: var(--icon-arrow)
}
.lg-prev, .lg-next, .lg-toolbar .lg-close {
    font-size: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.lg-prev {
    left: 0;

    @media screen and (min-width: 768px) {
        left: calc(20rem / 16);
    }
}
.lg-next {
    right: 0;

    @media screen and (min-width: 768px) {
        right: calc(20rem / 16);
    }
}

.lg-toolbar .lg-close:after {
    content: var(--icon-close);
}
.lg-toolbar .lg-zoom-in:after {
    content: var(--icon-zoomin);
}
.lg-toolbar .lg-zoom-out:after {
    content: var(--icon-zoomout);
}

.lightbox-item {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.lightbox-item__btn {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: calc(18rem/16);
    line-height: 1;
    color: #fff;
    text-shadow: 0 calc(1rem / 16) calc(3rem / 16) rgba(0, 0, 0, .4);
    padding: calc(10rem / 16);
}


/* white lightbox */
.lg-container--light .lg-backdrop {
    background-color: #fff;
}
.lg-container--light .lg-close,
.lg-container--light .lg-counter {
    color: var(--color-default);
}
.lg-container--light .lg-next,
.lg-container--light .lg-prev {
    color: var(--color-default);
    background-color: transparent;
}
.lg-container--light .lg-close:hover,
.lg-container--light .lg-next:hover,
.lg-container--light .lg-prev:hover {
    color: var(--color-primary);
}