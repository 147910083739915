.jobs-detail{
    padding: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(44rem/16);
    }
}
.jobs-detail__stats{
    font-size: calc(13rem/16);
    line-height: calc(24/13);
}
.jobs-detail__stats-item + .jobs-detail__stats-item{
    padding-top: calc(11rem/16);
    margin-top: calc(11rem/16);
    position: relative;
    @media screen and (min-width: 768px){
        margin-top: 0;
        padding-top: 0;
        padding-left: calc(22rem/16);
        margin-left: calc(22rem/16);
    }
}
.jobs-detail__stats-item + .jobs-detail__stats-item:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: calc(1rem/16);
    width: 50%;
    opacity: 0.8;
    background-color: var(--color-grey-50);
    border-radius: calc(0.5rem/16);
    @media screen and (min-width: 768px){
        height: 100%;
        width: calc(1rem/16);
    }
}