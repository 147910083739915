.data-teaser {
    display: grid;
    gap: calc(11rem / 16);
    background: #fff;
    border-radius: calc(6rem / 16);
    padding: calc(11rem / 16);
    font-size: calc(13rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(22rem / 16);
        gap: calc(33rem / 16);
        grid-template-columns: 14.7% 1fr;
    }
}
.data-teaser__title {
    font-size: calc(14rem / 16);
    line-height: calc(21/18);
    letter-spacing: calc(1rem / 16);
    margin-bottom: calc(6rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
        margin-bottom: calc(12rem / 16);
    }
}
.data-teaser__content {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(10rem / 16);

    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr calc(240rem / 16);
    }
}
.data-teaser__data {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(22rem / 16);
        grid-template-columns: 22% 22% 22% 1fr;
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 18% 18% 18% 1fr;
        gap: calc(10rem / 16);
    }
}