.wysiwyg-area .wysiwyg {
    @media screen and (max-width: 767px) {
        max-width: 100%;
        overflow-x: auto;
    }
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(26rem / 16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg > ul{
    list-style-type: none;
    padding-left: 0;
}
.wysiwyg > ul > li{
    position: relative;
    padding-left: calc(11rem/16);
}
.wysiwyg > ul > li:before{
    content: var(--icon-bullet);
    font-family: iconfont;
    position: absolute;
    left: 0;
    top: calc(9rem/16);
    height: calc(6rem/16);
    font-size: calc(6rem/16);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    @media screen and (min-width: 768px){
        top: calc(10rem/16);
    }
}
.wysiwyg > ul > li + li{
    @media screen and (min-width: 768px){
        margin-top: calc(6rem/16);
    }
}

.wysiwyg--lg{
    font-size: calc(15rem/16);
    color: var(--color-dark);
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        line-height: calc(28/18);
    }
}
.wysiwyg--lg > ul > li{
    padding-left: calc(17rem/16);
    @media screen and (min-width: 768px){
        padding-left: calc(21rem/16);
    }
}
.wysiwyg--lg > ul > li:before{
    font-size: calc(11rem/16);
}
.wysiwyg--lg > ul > li + li{
    margin-top: calc(11rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(23rem/16);
    }
}

/* tables in bootstrap/scss/tables.scss */

/*wysiwyg area*/
.wysiwyg-area--left{
    @media screen and (min-width: 768px){
        margin-left: calc(84rem/16);
    }
}
.wysiwyg-area__intro{
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    line-height: calc(28/15);
    margin-bottom: calc(21rem/16);
    color: var(--color-dark-grey);
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        margin-bottom: calc(44rem/16);
    }
}
.wysiwyg-area__content{
    color: var(--color-dark-grey);
}
.wysiwyg-area__content h2,
.wysiwyg-area__content h3,
.wysiwyg-area__content h4,
.wysiwyg-area__content h5,
.wysiwyg-area__content h6 {
    color: var(--color-default);
}

/*wysiwyg with buttons*/
.wysiwyg-with-buttons__text{
    line-height: calc(24/15);
    @media screen and (min-width: 768px){
        max-width: calc(690rem/16);
        line-height: calc(26/15);
    }
}