.modal-header {
    padding: calc(22rem / 16) calc(30rem / 16) calc(14rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(44rem / 16) calc(44rem / 16) calc(24rem / 16);
    }
}
.modal-body {
    padding: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(44rem / 16);
    }
}
.modal-footer {
    padding: 0 calc(22rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: 0 calc(44rem / 16) calc(44rem / 16);
    }
}
.modal-header .btn-close {
    background: none;
    position: absolute;
    right: calc(14rem / 16);
    top: calc(14rem / 16);
    padding: calc(5rem / 16);
    line-height: 1;
    color: var(--color-grey-50);
    opacity: 1;

    @media screen and (min-width: 768px) {
        right: calc(34rem / 16);
        top: calc(34rem / 16);
    }
}
.modal-header .btn-close:before {
    content: var(--icon-close);
    font-family: iconfont;
}
.modal-header .btn-close:hover {
    color: var(--color-primary);
}
.modal-header__logo {
    width: calc(30rem / 16);
    height: auto;
}

/* sizes */
.modal-sm {
    font-size: calc(13rem / 16);
    line-height: calc(20/13);
}
.modal-sm .modal-title {
    font-size: calc(15rem / 16);
}
.modal-sm .modal-header {
    @media screen and (min-width: 768px) {
        padding: calc(30rem / 16) calc(33rem / 16) calc(20rem / 16);
    }
}
.modal-sm .modal-body {
    //padding: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(33rem / 16);
    }
}
.modal-sm .modal-footer {
    @media screen and (min-width: 768px) {
        padding: 0 calc(33rem / 16) calc(33rem / 16);
    }
}

.modal-header + .modal-body.modal-body {
    padding-top: 0;
}

.modal-sm .wysiwyg > p:not(:last-child) {
    margin-bottom: calc(12rem / 16);
}

.modal-xl.modal-xl {
    max-width: calc(1420rem / 16);
}


.modal.is-disabled .modal-body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.3);
}
.modal-body__toast {
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* scrolling body */
.modal-body__scrolling.modal-body__scrolling {
    padding: 0 calc(30rem / 16);
    margin: 0 calc(-30rem / 16);
    max-height: 70vh;
}

/* draggable modal */
.react-draggable .modal-title,
.modal--draggable .modal-title {
    cursor: move;
    width: 100%;
}
.is-dragging .modal-dialog,
.modal.fade .modal-dialog.react-draggable-dragging {
    transition: none;
    opacity: .9;
}
.modal--draggable .modal-lg .modal-title,
.react-draggable .modal-title {
    margin: calc(-22rem / 16) calc(-30rem / 16) 0 calc(-22rem / 16);
    padding: calc(22rem / 16) calc(30rem / 16) 0 calc(22rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(-44rem / 16) calc(-44rem / 16) 0;
        padding: calc(44rem / 16) calc(44rem / 16) 0;
    }
}

.modal--webcut-import .modal-dialog {
    @media screen and (min-width: 768px) {
        width: 85vw;
        max-width: none;
    }
}
.modal--draggable .modal-sm .modal-title {
    margin: calc(-22rem / 16) calc(-30rem / 16) calc(-14rem / 16) calc(-22rem / 16);
    padding: calc(22rem / 16) calc(30rem / 16) calc(14rem / 16) calc(22rem / 16);

    @media screen and (min-width: 768px) {
        margin: calc(-30rem / 16) calc(-33rem / 16) calc(-20rem / 16) calc(-33rem / 16);
        padding: calc(30rem / 16) calc(33rem / 16) calc(20rem / 16) calc(33rem / 16);
    }
}