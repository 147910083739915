.navbar-block--bg {
    background: #fff;
    padding: calc(33rem / 16);
}
.navbar-block__back-icon {
    font-size: calc(8rem / 16);
    margin-right: calc(3rem / 16);
    vertical-align: 0.1em;
}
.navbar-block__icon {
    font-size: calc(38rem / 16);
}
.navbar-block__list--level-0 {
    @media screen and (min-width: 768px) {
        column-count: 2;
        column-gap: calc(24rem / 16);
    }
}
.navbar-block__link {
    display: flex;
    align-items: center;
    font-size: calc(14rem / 16);
    line-height: calc(18/14);
    padding: calc(6rem / 16) 0;
    letter-spacing: calc(.2rem / 16);
}
.navbar-block__link-icon {
    font-size: calc(8rem / 16);
    margin-left: calc(5rem / 16);
}
.navbar-block__item--level-0 {
    break-inside: avoid-column;
}

/* level 1 */
.navbar-block__list--level-1 {
    padding: 0 0 calc(3rem / 16) calc(22rem / 16);
}
.navbar-block__item--level-1 + .navbar-block__item--level-1 {
    margin-top: calc(3rem / 16);
}
.navbar-block__link--level-1 {
    padding: calc(2rem / 16) 0;
    color: var(--color-grey-50);
}

/* small navbar block */
.navbar-block--sm .navbar-block__list--level-0 {
    column-count: 1;
}
.navbar-block--sm .navbar-block__list--level-1 {
    padding: 0 0 calc(3rem / 16) calc(12rem / 16);
}