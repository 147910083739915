.quick-filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    grid-gap: calc(5rem / 16);

    @media screen and (min-width: 768px) {
        grid-gap: calc(10rem / 16);
    }
}
.quick-filter:empty {
    margin: 0;
}
.quick-filter__btn {
    border: 0;
    background: #fff;
    font-size: calc(13rem / 16);
    line-height: calc(15/13);
    color: var(--color-dark-grey);
    padding: calc(6rem / 16) calc(10rem / 16);
    display: flex;
    align-items: center;
}
.quick-filter__btn-icon {
    font-size: calc(9rem / 16);
    margin-left: calc(6rem / 16);
    transition: color 200ms ease-in-out;
}
.quick-filter__btn:hover .quick-filter__btn-icon {
    color: var(--color-primary);
}