.product-img-teaser {
    background: #fff;
    font-size: calc(13rem / 16);
    position: relative;

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }
}

@media screen and (min-width: 768px) {
    .product-img-teaser:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
        opacity: 0;
        transition: opacity 200ms ease-in-out;
        border-radius: calc(6rem / 16);
    }
    .product-img-teaser:hover:before {
        opacity: 1;
    }
}
.product-img-teaser__content {
    padding: calc(20rem / 16) calc(22rem / 16);
    justify-content: flex-end;
}
.product-img-teaser__content-title {
    font-size: calc(15rem / 16);
    line-height: calc(22/18);
    letter-spacing: calc(1rem / 16);

    @media screen and (min-width: 1200px) {
        font-size: calc(18rem / 16);
    }
}
.product-img-teaser__img {
    border-top-left-radius: calc(6rem / 16);
    border-top-right-radius: calc(6rem / 16);
    border: calc(1rem / 16) solid var(--color-light-grey);
}

/* sizes */
.product-img-teaser--md {
    line-height: calc(14/13);
}
.product-img-teaser--md .product-img-teaser__content-title {
    text-transform: none;
    color: var(--color-dark-grey);
    font-size: calc(13rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
/* styles */
.product-img-teaser--padding {
    padding: calc(11rem / 16);

    @media screen and (min-width: 1200px) {
        padding: calc(22rem / 16);
    }
}
.product-img-teaser--padding .product-img-teaser__img {
    border-radius: calc(6rem / 16);
}
.product-img-teaser--padding .product-img-teaser__content  {
    padding: calc(16rem / 16) 0 0;
    justify-content: flex-start;
}