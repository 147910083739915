.img-with-caption__content{
    font-size: calc(24rem/16);
    line-height: calc(32/24);
    color: var(--color-dark-grey);
    @media screen and (max-width: 767px){
        padding: 0 calc(22rem/16);
        margin-top: calc(22rem/16);
    }
}
.img-with-caption__content.img-with-caption__content-left{
    @media screen and (min-width: 768px){
        padding-right: calc(44rem/16);
    }
}
.img-with-caption__content.img-with-caption__content-right{
    @media screen and (min-width: 768px){
        padding-left: calc(44rem/16);
    }
}
