.callback__button{
    position: fixed;
    bottom: calc(20rem / 16);
    background: var(--color-primary);
    right: 0;
    transform: rotate(-90deg) translateX(100%);
    transform-origin: right bottom;
    border-top-right-radius: calc(6rem/16);
    border-top-left-radius: calc(6rem/16);
    padding: calc(9rem/16);
    font-size: calc(10rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    z-index: 10;
    color: #FFF;
    font-family: var(--font-default-light);
    box-shadow: 0 0 calc(30rem/16) 0 rgba(62,61,64,0.5);
    transition: background-color, transform 200ms ease-in-out;
    outline: none;
    border: none;
    @media screen and (min-width: 768px){
        padding: calc(11rem/16);
        font-size: calc(12rem/16);
    }
}
.callback__button:hover{
    background-color: var(--color-primary-light);
}
.callback__button.is-active{
    transform: rotate(-90deg) translateX(100%) translateY(100%);
}

.callback__content{
    background-color: #FFF;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: calc(100vw - calc(44rem/16));
    margin: calc(22rem/16);
    box-shadow: 0 0 calc(20rem/16) 0 rgba(62,61,64,0.2);
    padding: calc(33rem/16);
    border-radius: calc(6rem/16);
    z-index: 10;
    transition: transform 200ms ease-in-out;
    transform: translateX(120%);
    @media screen and (min-width: 768px){
        max-width: calc(600rem/16);
        margin: calc(55rem/16);
    }
}
.callback__content.is-active{
    transform: translateX(0);
}
.callback__content-logo{
    max-height: calc(14rem/16);
}
.callback__content-form a {
    color: var(--color-primary);
    text-decoration: underline;
}