.info-box{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 75vw;
    margin: calc(22rem/16);
    max-width: 100%;
    z-index: 10;
    transform: translateY( calc(100% + calc(55rem/16)) );
    transition: transform 200ms ease-in-out;
    @media screen and (min-width: 768px){
        margin: calc(55rem/16);
        max-width: calc(432rem/16);
    }
}
.info-box.is-open{
    transform: translateY(0);
}
.info-box:before{
    content: '';
    background-color: var(--color-primary);
    opacity: 0.7;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    z-index: 1;
    border-radius: calc(6rem/16);
}
.info-box__content{
    padding: calc(22rem/16);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFF;
    @media screen and (min-width: 768px){
        padding: calc(33rem/16);
    }
}
.info-box__logo{
    max-height: calc(14rem/16);
}
.info-box__content > .link-with-arrow{
    font-size: calc(13rem/16);
}
.info-box__content > .link-with-arrow__icon{
    font-size: calc(8rem/16);
}
.info-box .btn-close {
    background: none;
    line-height: 1;
    color: var(--color-grey-50);
    opacity: 1;
}
.info-box .btn-close:before {
    content: var(--icon-close);
    font-family: iconfont;
}