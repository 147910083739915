.flag {
    display: inline-block;
    background-repeat: no-repeat;
    border-radius: 50%;
    transform: scale(0.75);
    background-image: url(./static/img/flags/sprite.svg);
}
.flag-jafgroup-de {
    width: 32px;
    height: 32px;
    background-position: 0 0;
}
.flag-jafgroup-fr {
    width: 32px;
    height: 32px;
    background-position: -48px 0;
}
.flag-jafgroup-en {
    width: 32px;
    height: 32px;
    background-position: -96px 0;
}
.flag-rs {
    width: 48px;
    height: 32px;
    background-position: -144px 0;
}
.flag-de {
    width: 32px;
    height: 32px;
    background-position: -240px 0;
}
.flag-ba {
    width: 32px;
    height: 32px;
    background-position: -288px 0;
}
.flag-cz {
    width: 32px;
    height: 32px;
    background-position: -336px 0;
}
.flag-hr {
    width: 32px;
    height: 32px;
    background-position: -384px 0;
}
.flag-hu {
    width: 32px;
    height: 32px;
    background-position: -432px 0;
}
.flag-int {
    width: 32px;
    height: 32px;
    background-position: -480px 0;
}
.flag-pl {
    width: 32px;
    height: 32px;
    background-position: -528px 0;
}
.flag-ro {
    width: 32px;
    height: 32px;
    background-position: -576px 0;
}
.flag-ru {
    width: 32px;
    height: 32px;
    background-position: -624px 0;
}
.flag-si {
    width: 32px;
    height: 32px;
    background-position: -672px 0;
}
.flag-sk {
    width: 32px;
    height: 32px;
    background-position: -720px 0;
}
.flag-at {
    width: 32px;
    height: 32px;
    background-position: 0 -48px;
}
.flag-bg {
    width: 32px;
    height: 32px;
    background-position: -48px -48px;
}