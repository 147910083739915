.accessories-teaser {
    background: #fff;
    padding: calc(10rem / 16);
    grid-gap: calc(11rem / 16);
    font-size: calc(13rem / 16);
    line-height: calc(16 / 13);
    position: relative;

    @media screen and (min-width: 768px) {
        grid-template-areas:
            "main price"
            "main form";
        grid-template-columns: 1fr calc(220rem / 16);
    }

    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr calc(280rem / 16);
    }

    @media screen and (min-width: 1600px) {
        padding: calc(22rem / 16);
        grid-template-columns: 50% minmax(calc(175rem / 16), 18%) 30%;
        grid-template-areas: "main price form";
    }
}

.accessories-teaser__form.is-disabled .number-spinner,
.accessories-teaser__form.is-disabled button {
    opacity: .5;
    pointer-events: none;
}

@media screen and (min-width: 768px) {
    .accessories-teaser__price {grid-area: price;}
    .accessories-teaser__form {grid-area: form;}
    .accessories-teaser__main {grid-area: main;}
}

.accessories-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    box-shadow: 0 0 calc(20rem / 16) 0 rgba(62,61,64,0.3);
    opacity: .5;
    transition: opacity 200ms ease-in-out;
    border-radius: calc(6rem / 16);
}
.accessories-teaser:hover:before {
    opacity: 1;
}

.accessories-teaser__title {
    font-size: calc(15rem / 16);
    letter-spacing: normal;
    line-height: calc(20/15);

    @media screen and (min-width: 768px) {
        letter-spacing: calc(1rem / 16);
    }
}
.accessories-teaser__actions {
    @media screen and (max-width: 767px) {
        position: absolute;
        right: calc(10rem / 16);
        top: calc(10rem / 16);
        width: calc(44rem / 16);
    }
}


.accessories-teaser__meta {
    margin-top: calc(16rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(6rem / 16);
        grid-template-columns: repeat(5, min-content);
    }
    @media screen and (min-width: 1200px) {
        margin-top: calc(16rem / 16);
    }
}
.accessories-teaser__meta-item {
    @media screen and (min-width: 768px) {
        position: relative;
        width: calc(255rem / 16);
    }

    @media screen and (min-width: 1400px) {
        width: calc(340rem / 16);
    }
}
@media screen and (min-width: 768px) {

    .accessories-teaser__meta-item.accessories-teaser__meta-item--fill {
        width: calc(118rem / 16);
    }

    .accessories-teaser__meta-item:nth-last-child(n+3)~.accessories-teaser__meta-item {
        width: calc(98rem / 16);
    }

    .accessories-teaser__meta-item:not(:last-child):after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-left: calc(1rem / 16) solid var(--color-light-grey);
    }
}

.accessories-teaser__meta-item:not(:last-child) {
    @media screen and (min-width: 768px) {
        padding-right: calc(3rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding-right: calc(12rem / 16);
    }
}

.accessories-teaser .accessories-teaser__meta-item--fill {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        grid-column-start: 1;
        grid-column-end: 6;
        margin-bottom: calc(5rem / 16);
        width: auto;
        min-width: calc(80rem / 16);
    }
}
.accessories-teaser__meta-item.accessories-teaser__meta-item--fill:after {
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        content: none;
    }
}


.accessories-teaser.accessories-teaser--data-only {
    @media screen and (min-width: 768px) {
        grid-template-areas:
        "main price"
        "main form";
        grid-template-columns: 1fr;
    }
}
.accessories-teaser--data-only .accessories-teaser__meta {
    @media screen and (min-width: 768px) {
        grid-template-columns: calc(118rem / 16) repeat(auto-fill, calc(98rem / 16));
    }
}