.form-select {
    height: calc(44rem / 16);
    padding: calc(10rem / 16) calc(12rem / 16) calc(8rem / 16);
    //font-size: calc(16rem / 16);
    font-size: calc(13rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16);
        //font-size: calc(13rem / 16);
    }
}
.form-select:focus {
    box-shadow: none;
}

/* sizes */
.form-select-lg {
    height: calc(55rem / 16);
    padding: calc(8rem / 16) calc(22rem / 16);
}
.form-select-xl {
    @media screen and (min-width: 768px){
        height: calc(66rem / 16);
        padding: calc(16rem / 16) calc(22rem / 16);
    }

}
/* colors */
.form-select--grey {
    background-color: var(--color-light);
    border-color: var(--color-light);
}
.form-select--highlight {
    border-color: var(--color-primary);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='#E95B10' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
}
.form-select--highlight:hover {color: var(--color-primary);}
.form-select--highlight:hover option {
    color: var(--color-dark);
}

.form-select--dark {
    border-color: var(--color-grey-50);
}

/* button styled like a select */
.btn.form-select-btn,
.form-select-btn {
    font-size: calc(12rem / 16);
    padding-right: calc(25rem / 16);
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
        padding-right: calc(40rem / 16);
    }
}
.form-select-btn:after {
    content: var(--icon-chevron);
    font-family: iconfont;
    font-size: calc(5rem / 16);
    position: absolute;
    right: calc(12rem / 16);
    top: 50%;
    transform: translateY(-50%);

    @media screen and (min-width: 1200px) {
        right: calc(22rem / 16);
    }
}
.form-select-btn__value {
    font-size: calc(12rem / 16);
    padding-left: calc(10rem / 16);
    margin-left: calc(5rem / 16);
    color: var(--color-dark-grey);
    position: relative;

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
    }
}
.form-select-btn__value:after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(-4rem/16);
    bottom: calc(-2rem/16);
    width: calc(1rem / 16);
    background: var(--color-grey-50);
    opacity: .3;
}
.form-select-btn__icon {
    font-size: calc(5rem / 16);
}

//.form-select--plain {
//    cursor: pointer;
//    width: auto;
//    border: 0;
//    padding: 0;
//    padding-right: calc(18rem / 16);
//    height: auto;
//    font-size: calc(13rem / 16);
//    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{currentColor}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
//    background-position: right center;
//    background-size: calc(11rem / 16) calc(11rem / 16);
//
//    @media screen and (min-width: 768px) {
//        font-size: calc(15rem / 16);
//    }
//}

.form-select--webcut {
    font-family: webcutedges, Inter-Regular, sans-serif;
}

/* react select */
.form-select-react .form-select-react__control {
    border-color: var(--color-grey-50);
    height: calc(44rem / 16);
    font-size: calc(13rem / 16);
}

.form-select.is-empty,
.form-select-react.is-empty .form-select-react__control,
.form-select-react.is-invalid .form-select-react__control {
    border-color: var(--color-danger);
}
.form-select-react.is-invalid .form-select-react__value-container {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23C64C4C'%3E%3Ccircle cx='6' cy='6' r='4.5' stroke='%23C64C4C'/%3E%3Cpath stroke='%23fff' stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fff'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select-react__value-container {
    padding: calc(10rem / 16) calc(12rem / 16) calc(8rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16);
    }
}
.form-select-react__indicator-separator {
    display: none;
}
.form-select-react__indicator svg {
    height: calc(15rem / 16);
    width: calc(15rem / 16);
}
.form-select-react__option.form-select-react__option {
    font-size: calc(13rem / 16);
    background: none;
    padding: calc(4rem / 16) calc(10rem / 16);
    cursor: pointer;

    &:hover {
        color: var(--color-primary);
    }
}
.form-select-react__option.form-select-react__option--is-selected {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
}

.form-select-react__option.form-select-react__option--is-disabled {
    display: none;
}