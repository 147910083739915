.magazine-teaser{
    box-shadow: 0 10px 20px 0 rgba(62,61,64,0.3);
    overflow: hidden;
}
.magazine-teaser-slider .magazine-teaser{
    box-shadow: 0 5px 10px 0 rgba(62,61,64,0.3);
}
.magazine-teaser:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(49,49,49,0) 0%, #313131 100%);
    opacity: 0.9;
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
}
.magazine-teaser:hover .magazine-teaser__content{
    @media screen and (min-width: 768px){
        transform: translateY(0);
    }
}
.magazine-teaser__content{
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: calc(22rem/16);
    color: #fff;
    transform: translateY(calc(44rem/16));
    transition: transform 200ms ease-in-out;
}
.magazine-teaser__title{
    max-width: calc(350rem/16);
    font-size: calc(15rem/16);
    line-height: calc(18/15);
    letter-spacing: calc(1rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    @media screen and (min-width: 1200px){
        font-size: calc(18rem/16);
        line-height: calc(23/18);
    }
}
.magazine-teaser__content--lg{
    background-color: #FFF;
    color: var(--color-default);
    border-bottom-right-radius: calc(6rem/16);
    border-bottom-left-radius: calc(6rem/16);
}
.magazine-teaser__content--lg .magazine-teaser__title{
    color: var(--color-primary);
}
.magazine-teaser__text {
    font-size: calc(13rem/16);
    line-height: calc(26/15);
    color: var(--color-dark-grey);

    @media screen and (min-width: 1200px){
        font-size: calc(15rem/16);
    }
}
.magazine-teaser__text > p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.magazine-teaser__link-icon{
    font-size: calc(10rem/16);
}