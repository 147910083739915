.loading-overlay-container {
    position: relative;
    overflow: hidden;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    color: var(--color-primary);
    box-shadow: 0 0 calc(64rem / 16) calc(20rem / 16) #fff;
    z-index: 10;
    text-align: center;
    padding-top: calc(70rem / 16);
}
.loading-overlay__inner {
    position: sticky;
    top: 50%;
}

.loading-overlay--centered {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-overlay--content {
    position: relative;
    background: none;
    box-shadow: none;
    padding: calc(50rem / 16) 0;
}
.loading-overlay--content-sm {
    padding: calc(20rem / 16) 0;
}
.loading-overlay--content .loading-overlay__inner {
    position: relative;
    top: 0;
}

.loading-overlay--no-shadow {
    box-shadow: none;
}

.loading-overlay--btn {
    background: none;
    color: #fff;
}