.download-list {
    position: relative;
}
.download-list__nav {
    position: sticky;
    padding: calc(6rem / 16) 0;
    top: calc(104rem / 16);
    background: var(--color-light);
    font-size: calc(13rem / 16);
    z-index: 2;

    @media screen and (min-width: 768px) {
        top: calc(140rem / 16);
        padding: calc(12rem / 16) 0;
    }
}
.download-list__nav-list {
    display: flex;
    gap: calc(44rem / 16);
    margin: 0 calc(-22rem / 16);
    padding: 0 calc(22rem / 16);

    @media screen and (min-width: 768px) {
        margin: 0;
        padding: 0;
        width: 100%;
    }
}
.download-list__nav-link {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    padding: calc(5rem / 16) 0;
    position: relative;
}
.download-list__nav-link:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(44rem / 16);
    height: calc(2rem / 16);
    transform: scaleX(0) translateX(-50%);
    transform-origin: left;
    transition: transform 200ms ease-in-out;
    background: var(--color-primary);
}

.active.download-list__nav-link {
    color: var(--color-default);
}
.active.download-list__nav-link:before {
    transform: scaleX(1) translateX(-50%);
}

.download-list__item {
    background: #fff;
    border-radius: calc(6rem / 16);
    padding: calc(11rem / 16);
    display: grid;
    grid-template-columns: calc(58rem / 16) 1fr;
    gap: calc(22rem / 16);
    align-items: center;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        grid-template-columns: calc(58rem / 16) 1fr 56%;
    }
}
.download-list__item + .download-list__item {
    margin-top: calc(5rem / 16);
}
.download-list__item-title {
    font-size: calc(15rem / 16);
    line-height: calc(19/15);
}
.download-list__item-detail {
    display: grid;
    grid-template-columns: 30% 22% 1fr;
    gap: calc(4rem / 16);
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 767px) {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    @media screen and (min-width: 768px) {
        gap: calc(11rem / 16);
        grid-template-columns: 1fr calc(138rem / 16) 43%;
    }
}