.asset-video{
    cursor: pointer;
    position: relative;
}
.asset-video__video {
    width: 100%;
}
.asset-video__poster {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.asset-video__poster-img{
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.asset-video__play{
    z-index: 2;
    width: calc(66rem/16);
    height: calc(66rem/16);
    font-size: calc(16rem/16);
    color: var(--color-dark);
    border-radius: 50%;
    border: none;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40%;
    left: 40%;
    @media screen and (min-width: 768px) {
        top: 45%;
        left: 45%;
        width: calc(88rem / 16);
        height: calc(88rem / 16);
        font-size: calc(22rem / 16);
    }
}
.asset-video__icon{
    margin-left: calc(5rem/16);
}
.is-playing .asset-video__poster,
.is-playing .asset-video__play {
    display: none;
}

/* Video Lightbox */

.lightbox-video {
    cursor: pointer;
}
.lightbox-video__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: calc(45rem/16);
    color: var(--color-light);
    transform: translate(-50%, -50%);

    @media screen and (min-width: 768px) {
        font-size: 2.5rem;
    }

    @media screen and (min-width: 1200px) {
        font-size: 3.5rem;
    }
}
