.navbar-dropdown__menu {
    display: block;
    position: absolute;
    top: calc(100% + calc(1rem / 16));
    right: 0;
    z-index: 14;
    min-width: calc(356rem / 16);
    width: 100%;
    padding: calc(65rem / 16) calc(66rem / 16);
    background: #fff;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(0,0,0,0.1);
    font-size: calc(14rem / 16);
    letter-spacing: calc(.5rem / 16);
    border-radius: calc(6rem / 16);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
}
.navbar-dropdown__menu:hover,
.navbar-dropdown__btn:hover + .navbar-dropdown__menu {
    opacity: 1;
    visibility: visible;
}
.navbar-dropdown__title {
    font-size: calc(15rem / 16);
    letter-spacing: calc(1rem / 16);
}
.navbar-dropdown__item {
    font-size: calc(14rem / 16);
    letter-spacing: calc(.5rem / 16);
    display: flex;
    align-items: center;
}
.navbar-dropdown__item + .navbar-dropdown__item {
    margin-top: calc(16rem / 16);
}
.navbar-dropdown__item__arrow {
    font-size: calc(8rem / 16);
    margin-left: calc(7rem / 16);
    margin-top: calc(1rem / 16);
}