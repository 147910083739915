.navbar--checkout{
    padding-top: calc(22rem/16);
    padding-bottom: calc(22rem/16);
    background: var(--color-light);
    position: sticky;
    top: 0;
    z-index: 12;
    @media screen and (min-width: 768px) {
        padding: calc(18rem / 16) calc(22rem / 16) calc(24rem/16);
    }
}
.navbar--checkout:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
    pointer-events: none;
    z-index: 10;
}
.navbar--checkout .timeline{
    max-width: calc(612rem/16);
    width: 100%;
}