.form-control {
    font-size: calc(13rem / 16);
    padding: calc(12rem / 16);
    height: calc(44rem / 16);

    //@media screen and (min-width: 768px) {
    //    font-size: calc(13rem / 16);
    //}
}

.form-control:focus {
    box-shadow: none;
}

.form-select[disabled],
.form-control[disabled] {
    opacity: .7;
}
.form-control[readonly]:not(.form-control--datepicker) {
    background: var(--color-light);
    border-color: var(--color-light-grey);
}
.form-control.is-invalid {color: var(--color-danger);}

.form-control.is-empty,
.form-control[readonly].is-invalid {
    border-color: var(--color-danger)
}

.form-control.form-control--hide-validation {
    background-image: none;
    border-color: var(--color-light-grey);
}
.form-control.form-control--hide-validation:focus {
    border-color: var(--color-primary);
    box-shadow: inherit;
}

/* colors */
.form-control--dark {
    border-color: var(--color-grey-50);
}

/* sizes */
.form-control-sm {
    height: calc(30rem / 16);
    padding: calc(6rem / 16);
    font-size: calc(12rem / 16);
}
.form-control-lg {
    padding: calc(22rem / 16);
    height: calc(55rem / 16);
}
.form-control-xl {
    @media screen and (min-width: 768px){
        padding: calc(22rem / 16);
        height: calc(66rem / 16);
    }

}

.form-group,
.form__group {
    margin-bottom: calc(16rem / 16);
}

/* form control icons */
.form-control-icon {
    position: absolute;
    left: calc(22rem / 16);
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-primary);
    font-size: calc(12rem / 16);
    z-index: 1;
    pointer-events: none;
}
svg.form-control-icon {
    height: calc(12rem / 16);
}
.form-control-icon + .form-control {
    padding-left: calc(40rem / 16);
}
.form-control-icon--lg {
    font-size: calc(16rem / 16);
}

/* actions in input */
.form-control-actions {
    position: absolute;
    right: calc(1rem / 16);
    top: calc(1rem / 16);
    bottom: calc(1rem / 16);
    opacity: 0;
    transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
    visibility: hidden;
    padding: 0 calc(8rem / 16);
    background: #fff;
}
.form-control:not(:placeholder-shown) ~ .form-control-actions {
    opacity: 1;
    visibility: visible;
}
.form-control-actions__btn {
    background: none;
    border: 0;
    font-size: calc(12rem / 16);
    letter-spacing: calc(.5rem / 16);
    padding: calc(6rem / 16) calc(10rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    line-height: 1;
    position: relative;
}
.form-control-actions__btn + .form-control-actions__btn {
    margin-left: calc(3rem / 16);
}
.form-control-actions__btn + .form-control-actions__btn:before {
    content: '';
    position: absolute;
    left: calc(-3rem / 16);
    top: calc(4rem / 16);
    bottom: calc(4rem / 16);
    width: calc(1rem / 16);
    background: var(--color-grey-50);
}
.form-control-actions__btn-reset {
    color: var(--color-grey-50);
    position: relative;
}

.form-control-actions--visible {
    position: relative;
    background: #fff;
    border: calc(1rem / 16) solid var(--color-light-grey);
    border-left: 0;
    border-radius: calc(6rem / 16);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: calc(-20rem / 16);
    opacity: 1;
    visibility: visible;
    transition: border-color 150ms ease-in-out;
}
.form-control:focus ~ .form-control-actions--visible {
    border-color: var(--color-primary);
}
.form-control-actions--visible .form-control-actions__btn-reset {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
.form-control:not(:placeholder-shown) ~ .form-control-actions .form-control-actions__btn-reset {
    opacity: 1;
}

/* label */
.form-label {
    font-family: var(--font-default-bold);
    font-weight: var(--font-weight-bold);
    font-size: calc(12rem / 16);
    line-height: calc(15/12);
    color: var(--color-dark-grey);
    margin-bottom: calc(6rem / 16);
}
.form-label--sm {
    font-family: var(--font-default);
    font-weight: var(--font-weight-default);
    font-size: calc(10rem / 16);
}
.form-control--shadow {
    box-shadow: 0 calc(10rem / 16) calc(20rem / 16) 0 rgba(62,61,64,0.1);
}

textarea.form-control {
    height: auto;
}

/* input with suffix */
[data-input-suffix]:after {
    content: attr(data-input-suffix);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 calc(12rem / 16);
    font-size: calc(10rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
    }
}
[data-input-suffix] .form-control {
    padding-right: calc(40rem / 16);
}

/* range input */
.form-range-container {
    padding-top: calc(20rem / 16);
}
.form-range-output {
    position: absolute;
    top: calc(-20rem / 16);
    transform: translateX(-50%);
    white-space: nowrap;
    text-align: center;
}