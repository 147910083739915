.header-key-visual{
    background: #FFF;
}
.header-key-visual__content{
    padding: calc(22rem/16);
    @media screen and (min-width: 768px){
        padding: calc(66rem/16);
    }
}
.header-key-visual__title{
    font-size: calc(24rem/16);
    line-height: calc(29/24);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        line-height: calc(39/32);
    }
}
.header-key-visual__list{
    margin-top: calc(11rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(78rem/16);
    }
}
.header-key-visual__bg{
    min-height: calc(205rem/16);
    @media screen and (min-width: 768px){
        min-height: calc(480rem/16);
    }
}
.header-key-visual__img{
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: calc(6rem/16);
    border-bottom-right-radius: calc(6rem/16);
    @media screen and (min-width: 768px){
        border-bottom-left-radius: calc(0rem/16);
        border-top-right-radius: calc(6rem/16);
        border-bottom-right-radius: calc(6rem/16);
    }
}